export default [
    {
        "icon": "delete-outline",
        "name": "移除通知",
        "value": 0,
    },
    {
        "icon": "export-variant",
        "name": "转发到手环",
        "value": 1,
        "isShowOutput": true
    },
    {
        "icon": "cursor-default-click",
        "name": "点击通知",
        "value": 2,
    },
    {
        "icon": "cancel",
        "name": "结束后面规则",
        "value": 3,
    },
    {
        "icon": "format-vertical-align-top",
        "name": "固定通知",
        "value": 4,
        "isShowOutput": true
    },
    {
        "icon": "lightbulb-multiple-outline",
        "name": "提示通知",
        "value": 5,
        "isShowOutput": true
    },
    {
        "icon": "content-copy",
        "name": "复制通知",
        "value": 6,
        "isShowOutput": true
    },
    {
        "icon": "card-outline",
        "name": "点击通知按钮",
        "value": 7,
        "isShowOutput": true
    },
    {
        "icon": "apps",
        "name": "打开应用",
        "value": 8,
        "isShowSelect": true
    },
    {
        "icon": "volume-high",
        "name": "通知播报",
        "value": 9,
        "isShowOutput": true
    },
    {
        "icon": "subtitles-outline",
        "name": "弹幕通知",
        "value": 10,
        "isShowOutput": true
    },
    {
        "icon": "music-note",
        "name": "自定义铃声",
        "value": 11,
        "isShowShareApp": true
    },
    {
        "icon": "vibrate",
        "name": "自定义震动",
        "value": 12,
        "isShowShareApp": true
    },
    {
        "icon": "application-outline",
        "name": "分享到应用",
        "value": 13,
        "isShowShareServer": true
    },
    {
        "icon": "cloud-upload-outline",
        "name": "分享到服务器",
        "value": 14,
        "isShowShareServer": true
    },
    {
        "icon": "dock-window",
        "name": "弹框通知",
        "value": 15,
        "isShowShareServer": true
    },
];