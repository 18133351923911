import MyIndex from '../views/my/Index'
import MyInfo from '../views/my/Info'
import MyEdit from '../views/my/Edit'
import MyTransaction from '../views/my/Transaction'
import MyVip from '../views/my/Vip'
import MyRoom from '../views/my/Room'
import MyRule from '../views/my/Rule'
import MyFollow from '../views/my/Follow'
import MyFans from '../views/my/Fans'
import MyDevices from '../views/my/Devices'
import Exchange from '../views/my/Exchange'
import MyBuy from '../views/my/Buy'
import MyCode from '../views/my/Code'
import MyHistory from '../views/my/History'
import MyPrivacy from '../views/my/Privacy'

export default {
    path: '/my',
    name: '我的',
    component: MyIndex,
    children: [
        {
            path: "/",
            component: MyInfo,
            name: "我的"
        },
        {
            path: "edit",
            component: MyEdit,
            name: "编辑我的信息"
        },
        {
            path: "transaction",
            component: MyTransaction,
            name: "我的交易"
        },
        {
            path: "vip",
            component: MyVip,
            name: "我的会员"
        },
        {
            path: "room",
            component: MyRoom,
            name: "我的空间"
        },
        {
            path: "rule",
            component: MyRule,
            name: "我的规则"
        },
        {
            path: "follow",
            component: MyFollow,
            name: "我的关注"
        },
        {
            path: "fans",
            component: MyFans,
            name: "我的粉丝"
        },
        {
            path: "exchange",
            component: Exchange,
            name: "兑换中心"
        },
        {
            path: "devices",
            component: MyDevices,
            name: "设备管理"
        },
        {
            path: "buy",
            component: MyBuy,
            name: "我的订阅"
        },
        {
            path: "code",
            component: MyCode,
            name: "邀请码"
        },
        {
            path: "history",
            component: MyHistory,
            name: "浏览历史"
        },
        {
            path: "privacy",
            component: MyPrivacy,
            name: "隐私设置"
        },
    ]
};