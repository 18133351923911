var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"container","fluid":""}},[_c('v-row',{staticClass:"fill-height pa-3 pa-sm-0"},[_c('v-col',{staticClass:"hidden-sm-and-down",staticStyle:{"position":"relative"},attrs:{"md":"4","lg":"3"}},[_c('v-card',{ref:"notificationContainer",staticClass:"overflow-auto mt-10",staticStyle:{"height":"85vh"}},_vm._l((_vm.notificationList),function(item,key){return _c('div',{key:key},[_c('NotificationItem',{attrs:{"data":item,"index":key},on:{"removeNotification":_vm.removeNotification,"updateNotification":_vm.updateNotification}}),_c('v-divider')],1)}),0),_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"deep-purple mt-10","dark":"","absolute":"","top":"","left":"","fab":""},on:{"click":_vm.addNotification}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-col',{attrs:{"md":"8","lg":"8","sm":"12","offset-lg":"1","offset-md":"0"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" 创建规则 ")]),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 基础信息 ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"规则使用状态"},model:{value:(_vm.form.useState),callback:function ($$v) {_vm.$set(_vm.form, "useState", $$v)},expression:"form.useState"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('validation-provider',{attrs:{"name":"规则名称","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"规则标题","counter":35,"error-messages":errors,"outlined":"","messages":"该文本框会根据您选择【规则范围，执行动作，其他】生成对于的内容。"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":"规则描述","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"规则描述","error-messages":errors,"counter":1000,"outlined":"","messages":"该文本框会根据您选择【规则范围，执行动作，其他】生成对于的内容。"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}],null,true)}),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"规则版本","rules":"required|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"规则版本","type":"number","counter":5,"error-messages":errors,"outlined":""},model:{value:(_vm.form.ruleVersion),callback:function ($$v) {_vm.$set(_vm.form, "ruleVersion", $$v)},expression:"form.ruleVersion"}})]}}],null,true)})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"规则作者","outlined":"","value":"admin","disabled":""}})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 通知信道 ")]),_c('v-expansion-panel-content',_vm._l((_vm.channel),function(item){return _c('v-chip',{key:item.value,staticClass:"mt-2 mb-2 ms-3 me-3",attrs:{"input-value":_vm.form.channel.has(item.value),"filter":""},on:{"click":function($event){return _vm.selectChannel(item.value)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 规则范围 ")]),_c('v-expansion-panel-content',[_vm._l((_vm.ranges),function(item){return _c('v-chip',{key:item.value,staticClass:"mt-2 mb-2 ms-3 me-3",attrs:{"input-value":_vm.form.range===item.value,"filter":""},on:{"click":function($event){_vm.form.range=item.value}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.range!==-1),expression:"form.range!==-1"}],staticClass:"mt-2 mb-5"}),(_vm.form.range===0||_vm.form.range===2)?_c('v-row',[_c('v-col',{staticClass:"hidden-md-and-up",attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{attrs:{"label":"测试标题","counter":"5000","outlined":"","messages":_vm.testTitleMessage},model:{value:(_vm.form.testTitle),callback:function ($$v) {_vm.$set(_vm.form, "testTitle", $$v)},expression:"form.testTitle"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"标题规则","rules":"required|max:500|ruleIsTrue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"标题规则","counter":500,"outlined":"","error-messages":errors,"placeholder":"支持正则表达式和关键词"},model:{value:(_vm.form.ruleTitle),callback:function ($$v) {_vm.$set(_vm.form, "ruleTitle", $$v)},expression:"form.ruleTitle"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.form.range===1||_vm.form.range===2)?_c('v-row',[_c('v-col',{staticClass:"hidden-md-and-up",attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{attrs:{"label":"测试内容","counter":"5000","outlined":"","messages":_vm.testContentMessage},model:{value:(_vm.form.testContent),callback:function ($$v) {_vm.$set(_vm.form, "testContent", $$v)},expression:"form.testContent"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"内容规则","rules":"required|max:500|ruleIsTrue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"内容规则","counter":500,"outlined":"","error-messages":errors,"placeholder":"支持正则表达式和关键词"},model:{value:(_vm.form.ruleContent),callback:function ($$v) {_vm.$set(_vm.form, "ruleContent", $$v)},expression:"form.ruleContent"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.form.range===3)?_c('v-row',[_c('v-col',{staticClass:"hidden-md-and-up",attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{attrs:{"label":"测试按钮","counter":"1000","outlined":"","messages":_vm.testButtonMessage},model:{value:(_vm.form.testButton),callback:function ($$v) {_vm.$set(_vm.form, "testButton", $$v)},expression:"form.testButton"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"按钮规则","rules":"required|max:100|ruleIsTrue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"按钮规则","counter":100,"outlined":"","error-messages":errors,"placeholder":"支持正则表达式和关键词"},model:{value:(_vm.form.ruleButton),callback:function ($$v) {_vm.$set(_vm.form, "ruleButton", $$v)},expression:"form.ruleButton"}})]}}],null,true)})],1)],1):_vm._e()],2)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 执行动作 ")]),_c('v-expansion-panel-content',[_vm._l((_vm.events),function(item){return _c('v-chip',{key:item.value,staticClass:"mt-2 mb-2 ms-3 me-3",attrs:{"input-value":_vm.form.events.has(item.value),"filter":""},on:{"click":function($event){return _vm.selectEvent(item.value)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s('mdi-' + item.icon)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.range!==-1||_vm.isShowSelect||_vm.isShowShareApp||_vm.isShowShareServer),expression:"form.range!==-1||isShowSelect||isShowShareApp||isShowShareServer"}],staticClass:"mt-2 mb-5"}),(_vm.isShowOutput&&(_vm.form.range===0||_vm.form.range===2))?_c('validation-provider',{attrs:{"name":"输出标题","rules":"required|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"输出标题","outlined":"","counter":5000,"error-messages":errors,"messages":_vm.outputTitleMessage,"value":"##"},model:{value:(_vm.form.outputTitle),callback:function ($$v) {_vm.$set(_vm.form, "outputTitle", $$v)},expression:"form.outputTitle"}})]}}],null,true)}):_vm._e(),(_vm.isShowOutput&&(_vm.form.range===1||_vm.form.range===2))?_c('validation-provider',{attrs:{"name":"输出内容","rules":"required|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"输出内容","outlined":"","counter":5000,"error-messages":errors,"messages":_vm.outputContentMessage,"value":"##"},model:{value:(_vm.form.outputContent),callback:function ($$v) {_vm.$set(_vm.form, "outputContent", $$v)},expression:"form.outputContent"}})]}}],null,true)}):_vm._e(),(_vm.isShowSelect)?_c('validation-provider',{attrs:{"name":"选择应用","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.packageList,"outlined":"","chips":"","label":"选择应用","item-text":"name","item-value":"name","error-messages":errors,"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removePackage(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":data.item.avatar}})],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":data.item.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.group)}})],1)]]}}],null,true),model:{value:(_vm.form.selectPackage),callback:function ($$v) {_vm.$set(_vm.form, "selectPackage", $$v)},expression:"form.selectPackage"}})]}}],null,true)}):_vm._e(),(_vm.isShowShareApp)?_c('div',[_c('p',[_vm._v("发送到其他应用")]),_c('validation-provider',{attrs:{"name":"选择目标应用","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.packageList,"outlined":"","chips":"","label":"选择目标应用","item-text":"name","item-value":"name","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removePackage(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":data.item.avatar}})],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":data.item.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.group)}})],1)]]}}],null,true),model:{value:(_vm.form.selectShareApp),callback:function ($$v) {_vm.$set(_vm.form, "selectShareApp", $$v)},expression:"form.selectShareApp"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"发送到某个应用","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.form.shareApp),function(value,key){return _c('v-row',{key:key},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"属性：","error-messages":errors},model:{value:(value.key),callback:function ($$v) {_vm.$set(value, "key", $$v)},expression:"value.key"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"值：","error-messages":errors},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1),_c('v-col',[_c('v-btn-toggle',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeShareItem(_vm.form.shareApp,value)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addShareItem(_vm.form.shareApp)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)})}}],null,true)})],1):_vm._e(),(_vm.isShowShareServer)?_c('div',[_c('p',[_vm._v("基础信息")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"方法","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"方法","items":_vm.methods,"error-messages":errors}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":"URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"URL","error-messages":errors}})]}}],null,true)})],1)],1),_c('p',[_vm._v("请求头")]),_c('validation-provider',{attrs:{"name":"请求头","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.form.shareServer.header),function(value,key){return _c('v-row',{key:key},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"属性：","error-messages":errors},model:{value:(value.key),callback:function ($$v) {_vm.$set(value, "key", $$v)},expression:"value.key"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"值：","error-messages":errors},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1),_c('v-col',[_c('v-btn-toggle',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeShareItem(_vm.form.shareServer.header,value)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addShareItem(_vm.form.shareServer.header)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)})}}],null,true)}),_c('p',[_vm._v("数据")]),_c('validation-provider',{attrs:{"name":"数据","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.form.shareServer.data),function(value,key){return _c('v-row',{key:key},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"属性：","error-messages":errors},model:{value:(value.key),callback:function ($$v) {_vm.$set(value, "key", $$v)},expression:"value.key"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"值：","error-messages":errors},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1),_c('v-col',[_c('v-btn-toggle',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeShareItem(_vm.form.shareServer.data,value)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addShareItem(_vm.form.shareServer.data)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)})}}],null,true)})],1):_vm._e()],2)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 其他 ")]),_c('v-expansion-panel-content',[_c('v-chip',{attrs:{"input-value":_vm.form.isGlobal,"filter":""},on:{"click":function($event){_vm.form.isGlobal=!_vm.form.isGlobal}}},[_vm._v("全局规则 ")])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","text":"","disabled":invalid}},[_vm._v(" 保存 ")]),_c('v-btn',{attrs:{"text":""}},[_vm._v(" 清空 ")])],1)],1)],1)]}}])})],1)],1),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }