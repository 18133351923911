import {Delete, get, post, put} from './http'

const v1 = "api/v1"

export const recommendUser = (p) => get(`${v1}/recommend/user`, p)
export const recommendPackage = (p) => get(`${v1}/recommend/package`, p)
export const recommendRule = (p) => get(`${v1}/recommend/rule`, p)

export const rankingRuleScore = (p) => get(`${v1}/ranking/scoreRule`, p)
export const rankingRuleBuy = (p) => get(`${v1}/ranking/buyRule`, p)
export const rankingUserScore = (p) => get(`${v1}/ranking/scoreUser`, p)
export const rankingUserRule = (p) => get(`${v1}/ranking/ruleUser`, p)
export const rankingUserFollow = (p) => get(`${v1}/ranking/followUser`, p)
export const rankingPackageRule = (p) => get(`${v1}/ranking/rulePackage`, p)
export const rankingPackageFollow = (p) => get(`${v1}/ranking/followPackage`, p)


export const userShow = (uuid, p) => get(v1 + "/user/" + uuid, p)
export const userInfo = (uuid, p) => get(v1 + "/user/info", p)
export const userLogin = data => post(v1 + "/user/login", data)
export const userFollow = (uuid, p) => get(v1 + "/user/" + uuid + "/follow", p)
export const userFollows = (p) => get(v1 + "/user/follows", p)
export const userBuy = (uuid, p) => get(v1 + "/user/" + uuid + "/buy", p)
export const userUnFollow = (uuid, p) => get(v1 + "/user/" + uuid + "/unfollow", p)
export const userFollowUser = (uuid, p) => get(`${v1}/user/${uuid}/follow/user`, p)
export const userFollowPackage = (uuid, p) => get(`${v1}/user/${uuid}/follow/package`, p)
export const userFollowFans = (uuid, p) => get(`${v1}/user/${uuid}/follow/fans`, p)
export const userDevices = () => get(`${v1}/user/device`)
export const userDevice = (id) => Delete(`${v1}/user/device/${id}`)
export const userPrivacy = () => get(`${v1}/user/privacy`)
export const updateUserPrivacy = (config) => put(`${v1}/user/privacy`, config)

export const ruleList = (data, p) => post(v1 + "/rule/search", data, p)
export const rule = (rUid, p) => get(v1 + "/rule/" + rUid, p)
export const ruleUser = (uuid, p) => get(v1 + "/rule/" + uuid + "/list", p)
export const ruleBuy = (rUid, p) => post(v1 + "/rule/buy/" + rUid, p)

export const packageList = (content, searchType, searchColumn, orderType, p) => get(`${v1}/package/${content}/search/${searchType}/${searchColumn}/${orderType}`, p)
export const packageInfo = (id, p) => get(v1 + "/package/" + id, p)
export const packageFollow = (id, p) => get(v1 + "/package/" + id + "/follow", p)
export const packageUnFollow = (id, p) => get(v1 + "/package/" + id + "/unfollow", p)
export const packageFollowUser = (id, p) => get(v1 + "/package/" + id + "/follows?page=1&count=5", p)
export const packageTopUser = (id, p) => get(v1 + "/package/" + id + "/top/user?count=10", p)
export const packageTopRule = (id, p) => get(v1 + "/package/" + id + "/top/rule?count=10", p)

export const codeExchangeQuery = (code) => get(`${v1}/code/exchange/${code}`)
export const codeExchange = (code) => post(`${v1}/code/exchange/${code}`)