<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card
            elevation="0"
        >
          <v-list-item three-line>
            <v-list-item-avatar
                tile
                size="80"
            >
              <v-img
                  :src="user.avatar"
                  :alt="user.name"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-overline mb-4">
                {{ user.name }}的信息
              </div>
              <v-list-item-title
                  class="text-h5 mb-1"
                  v-text="user.name">
              </v-list-item-title>
              <v-list-item-subtitle
                  v-text="user.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <UserFollow
                :follow-state="user.follow"
                :index="0"
                @result="user.follow=!user.follow"
                :u-uid="user.uuid">
            </UserFollow>
          </v-list-item>

          <v-card-actions>
            <v-card
                elevation="0">
              <v-rating
                  length="5"
                  :value="user.score"
              ></v-rating>
              <v-overlay
                  absolute
                  opacity="0"
                  value="true"
              >
              </v-overlay>
            </v-card>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-tabs
              v-model="tab"
              align-with-title
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
                v-for="item in items"
                :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!--规则-->
            <v-tab-item>
              <NoData v-if="ruleLoading===false&&rules.length===0"></NoData>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      v-for="(rule,index) in rules"
                      :key="rule.rUid"
                  >
                    <v-card
                        outlined
                        class="rounded-xl"
                    >
                      <v-list-item three-line>
                        <v-list-item-content
                            @click="$router.push('/rules/'+ rule.rUid +'/info')"
                        >
                          <v-list-item-title v-text="rule.ruleName"></v-list-item-title>
                          <v-list-item-subtitle v-text="rule.ruleDescription">
                          </v-list-item-subtitle>
                          <v-card elevation="0">
                            <v-rating
                                :value="rule.star"
                                disabled="true"
                                length="5"
                            ></v-rating>
                            <v-overlay
                                absolute
                                value="true"
                                opacity="0"/>
                          </v-card>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                              text
                              disabled
                          >
                            <v-icon>mdi-currency-usd</v-icon>
                            <span v-text="rule.price">0</span>
                          </v-btn>

                          <rule-follow :buy="rule.buy" :r-uid="rule.rUid" :index="index"
                                       @resultBuy="updateRuleBuy"></rule-follow>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-center justify-center">
                    <!--换页-->
                    <div class="text-center">
                      <v-pagination
                          v-if="rules.length>0"
                          v-model="rulePage.index"
                          :length="rulePage.length"
                          :total-visible="7"
                          @input="loadRuleData()"
                      ></v-pagination>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!--关注-->
            <v-tab-item>
              <v-tabs
                  vertical
                  v-model="followTab"
              >
                <v-tab
                    v-for="follow in followItems"
                    v-text="follow"
                    :key="follow"></v-tab>
                <!--用户-->
                <v-tab-item>
                  <NoData v-if="userLoading===false&&users.length===0"></NoData>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          md="6"
                          lg="4"
                          v-for="(user,index) in users"
                          :key="user.rUid"
                      >
                        <v-card
                            class="mx-auto rounded-xl"
                            outlined>
                          <v-list-item three-line>
                            <v-list-item-avatar
                                @click="updateUserInfo(user.uuid)"
                                size="45"
                                >
                              <v-img
                                  :src="user.avatar"
                                  :alt="user.name"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content
                                @click="updateUserInfo(user.uuid)"
                            >
                              <v-list-item-title v-text="user.name"></v-list-item-title>
                              <v-list-item-subtitle v-if="user.description!==''"
                                                    v-text="user.description"></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <UserFollow
                                  :followState="user.follow"
                                  :uUid="user.uuid"
                                  :index="index"
                                  @result="updateUserFollowUser"
                              ></UserFollow>
                            </v-list-item-action>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex align-center justify-center">
                        <!--换页-->
                        <div class="text-center">
                          <v-pagination
                              v-if="users.length>0"
                              v-model="userPage.index"
                              :length="userPage.length"
                              :total-visible="7"
                              @input="loadUserFollowUser()"
                          ></v-pagination>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <!--应用-->
                <v-tab-item>
                  <NoData v-if="packageLoading===false&&packages.length===0"></NoData>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          md="6"
                          lg="4"
                          v-for="(packageInfo) in packages"
                          :key="packageInfo.id"
                      >
                        <v-card
                            class="mx-auto rounded-xl"
                            outlined
                            @click="$router.push('/packages/'+packageInfo.id+'/info')"
                        >
                          <v-list-item three-line>
                            <v-list-item-avatar
                                tile
                                size="80"

                            >
                              <v-img :src="packageInfo.logo"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                  class="text-h5 mb-1"
                                  v-text="packageInfo.name"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                  v-text="packageInfo.packageName"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex align-center justify-center">
                        <!--换页-->
                        <div class="text-center">
                          <v-pagination
                              v-if="packages.length>0"
                              v-model="packagePage.index"
                              :length="packagePage.length"
                              :total-visible="7"
                              @input="loadUserFollowPackage()"
                          ></v-pagination>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-tab-item>
            <!--粉丝-->
            <v-tab-item>
              <NoData v-if="followLoading===false&&follows.length===0"></NoData>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      v-for="(follow,index) in follows"
                      :key="follow.rUid"
                  >
                    <v-card
                        class="mx-auto rounded-xl"
                        outlined>
                      <v-list-item three-line>
                        <v-list-item-avatar
                            @click="updateUserInfo(follow.uuid)"
                            size="45"
                            >
                          <v-img
                              :src="follow.avatar"
                              :alt="follow.name"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content
                            @click="updateUserInfo(follow.uuid)"
                        >
                          <v-list-item-title v-text="follow.name"></v-list-item-title>
                          <v-list-item-subtitle v-if="follow.description!==''"
                                                v-text="follow.description"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <UserFollow
                              :followState="follow.follow"
                              :uUid="follow.uuid"
                              :index="index"
                              @result="updateUserFollow"
                          ></UserFollow>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-center justify-center">
                    <!--换页-->
                    <div class="text-center">
                      <v-pagination
                          v-if="follows.length>0"
                          v-model="followPage.index"
                          :length="followPage.length"
                          :total-visible="7"
                          @input="loadUserFollowFans()"
                      ></v-pagination>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {ruleUser, userFollowFans, userFollowPackage, userFollowUser, userShow} from "../../request/api";
import UserFollow from "../../components/UserFollow";
import NoData from "../../components/NoData";
import RuleFollow from "../../components/RuleFollow";

export default {
  name: "Info",
  components: {
    UserFollow,
    NoData,
    RuleFollow,
  },
  data: () => ({
    overlay: true,
    user: {},
    tab: null,
    items: [
      '规则', '关注', '粉丝',
    ],
    followTab: null,
    followItems: [
      '用户', '应用'
    ],
    ruleLoading: false,
    rules: [],
    rulePage: {
      "length": 1,
      "index": 1,
    },
    packageLoading: false,
    packages: [],
    packagePage: {
      "length": 1,
      "index": 1,
    },
    followLoading: false,
    follows: [],
    followPage: {
      "length": 1,
      "index": 1,
    },
    userLoading: false,
    users: [],
    userPage: {
      "length": 1,
      "index": 1,
    },
  }),
  methods: {
    loadRuleData() {
      ruleUser(this.user.uuid, {
        "page": this.rulePage.index,
      }).then(response => {
        this.ruleLoading = false
        this.rules = response.data.data.list
        this.rulePage.length = response.data.meta.last_page
      }).catch(() => {
        this.ruleLoading = false
      })
    },
    loadUserFollowUser() {
      userFollowUser(this.user.uuid, {
        "page": this.userPage.index,
        "count": 10,
      }).then(response => {
        this.userLoading = false
        this.users = response.data.data.list
        this.userPage.length = response.data.meta.last_page
      }).catch(() => {
        this.userLoading = false
      })
    },
    loadUserFollowPackage() {
      userFollowPackage(this.user.uuid, {
        "page": this.packagePage.index,
        "count": 10,
      }).then(response => {
        this.packageLoading = false
        this.packages = response.data.data.list
        this.packagePage.length = response.data.meta.last_page
      }).catch(() => {
        this.packageLoading = false
      })
    },
    loadUserFollowFans() {
      userFollowFans(this.user.uuid, {
        "page": this.followPage.index,
        "count": 10,
      }).then(response => {
        this.followLoading = false
        this.follows = response.data.data.list
        this.followPage.length = response.data.meta.last_page
      }).catch(() => {
        this.followLoading = false
      })
    },
    updateRuleBuy(data) {
      this.rules[data.index].follow = data.buy
    },
    updateUserFollowUser(data) {
      this.users[data.index].follow = data.follow
    },
    updateUserFollow(data) {
      this.follows[data.index].follow = data.follow
    },
    updateUserInfo(uuid) {
      this.overlay = true
      userShow(uuid)
          .then(response => {
            this.user = response.data.data
            this.loadRuleData()
            this.loadUserFollowUser()
            this.loadUserFollowPackage()
            this.loadUserFollowFans()
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })
      this.$router.push(`/users/${uuid}/info`).catch(err => err)
    }
  },
  created() {
    this.updateUserInfo(this.$route.params.uid)
  }
}
</script>

<style scoped>

</style>