export default [
    {
        "name": "标题",
        "value": 0,
    },
    {
        "name": "内容",
        "value": 1,
    },
    {
        "name": "按钮",
        "value": 3,
    },
];