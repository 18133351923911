<template>
  <v-container
      class="pb-16"
  >
    <!--工具栏-->
    <v-row>
      <v-col
          cols="8"
      >
        <h4
            class="text-h5 text-sm-h4"
            v-text="info.title"
        ></h4>
      </v-col>
      <v-col
          class="d-flex justify-end align-center"
      >
        <v-btn
            icon
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!--内容区-->
    <v-row
        class="mb-2"
    >
      <v-col
          cols="12"
          md="6"
          lg="3"
          v-for="rule in info.list"
          :key="rule.rUid"
      >
        <rule-card :rule="rule"></rule-card>
      </v-col>
    </v-row>
    <!--换页-->
    <div class="text-center">
      <v-pagination
          v-model="page.index"
          :length="page.length"
          :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import RuleCard from "../../components/RuleCard";
export default {
  name: "Gather",
  components: {RuleCard},
  data: () => ({
    page: {
      "length": 15,
      "index": 1,
    },
    info: {
      "title": "推荐使用",
      "type": "use",
      list: [
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
        {
          "rUid": "1231231313131",
          "packageIcon": "https://cdn.vuetifyjs.com/images/john.jpg",
          "packageName": "通知过滤",
          "ruleTitle": "我是规则标题",
          "ruleDescription": "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
          "uUid": "123131313131131",
          "avatar": "'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Serious&skinColor=Tanned",
          "uName": "Webb",
          "ruleScore": 5,
          "rulePrice": 10
        },
      ]
    },
  }),
}
</script>

<style scoped>

</style>