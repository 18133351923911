<template>
  <v-container>
    <v-row>
      <v-tabs
          v-model="followTab"
      >
        <v-tab
            v-for="follow in followItems"
            v-text="follow"
            :key="follow"></v-tab>
        <!--用户-->
        <v-tab-item>
          <NoData v-if="userLoading===false&&users.length===0"></NoData>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-for="(user,index) in users"
                  :key="user.rUid"
              >
                <v-card
                    class="mx-auto rounded-xl"
                    outlined>
                  <v-list-item three-line>
                    <v-list-item-avatar
                        @click="$router.push('/users/'+user.uuid+'/info')"
                        size="45"
                        >
                      <v-img
                          :src="user.avatar"
                          :alt="user.name"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content
                        @click="$router.push('/users/'+user.uuid+'/info')"
                    >
                      <v-list-item-title v-text="user.name"></v-list-item-title>
                      <v-list-item-subtitle v-if="user.description!==''"
                                            v-text="user.description"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <UserFollow
                          :followState="user.follow"
                          :uUid="user.uuid"
                          :index="index"
                          @result="updateUserFollowUser"
                      ></UserFollow>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center justify-center">
                <!--换页-->
                <div class="text-center">
                  <v-pagination
                      v-if="users.length>0"
                      v-model="userPage.index"
                      :length="userPage.length"
                      :total-visible="7"
                      @input="loadUserFollowUser()"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!--应用-->
        <v-tab-item>
          <NoData v-if="packageLoading===false&&packages.length===0"></NoData>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-for="(packageInfo) in packages"
                  :key="packageInfo.id"
              >
                <v-card
                    outlined
                    class="mx-auto rounded-xl"
                    @click="$router.push('/packages/'+packageInfo.id+'/info')"
                >
                  <v-list-item three-line>
                    <v-list-item-avatar
                        tile
                        size="80"

                    >
                      <v-img :src="packageInfo.logo"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                          class="text-h5 mb-1"
                          v-text="packageInfo.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                          v-text="packageInfo.packageName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center justify-center">
                <!--换页-->
                <div class="text-center">
                  <v-pagination
                      v-if="packages.length>0"
                      v-model="packagePage.index"
                      :length="packagePage.length"
                      :total-visible="7"
                      @input="loadUserFollowPackage()"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import {userFollowPackage, userFollowUser} from "../../request/api";
import UserFollow from "../../components/UserFollow";
import NoData from "../../components/NoData";

export default {
  name: "Follow",
  components: {
    UserFollow,
    NoData,
  },
  data: () => ({
    followTab: null,
    followItems: [
      '用户', '应用'
    ],
    userLoading: false,
    users: [],
    userPage: {
      "length": 1,
      "index": 1,
    },
    packageLoading: false,
    packages: [],
    packagePage: {
      "length": 1,
      "index": 1,
    },
    followLoading: false,
    follows: [],
    followPage: {
      "length": 1,
      "index": 1,
    },
  }),
  methods: {
    loadUserFollowUser() {
      userFollowUser(this.$store.state.user.uuid, {
        "page": this.userPage.index,
        "count": 10,
      }).then(response => {
        this.userLoading = false
        this.users = response.data.data.list
        this.userPage.length = response.data.meta.last_page
      }).catch(() => {
        this.userLoading = false
      })
    },
    loadUserFollowPackage() {
      userFollowPackage(this.$store.state.user.uuid, {
        "page": this.packagePage.index,
        "count": 10,
      }).then(response => {
        this.packageLoading = false
        this.packages = response.data.data.list
        this.packagePage.length = response.data.meta.last_page
      }).catch(() => {
        this.packageLoading = false
      })
    },
    updateUserFollowUser(data) {
      this.users[data.index].follow = data.follow
    },
  },
  created() {
    this.loadUserFollowUser()
    this.loadUserFollowPackage()
  }
}
</script>

<style scoped>

</style>