<template>
  <v-container
      class="pb-16 mt-4"
  >
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <!--创作者名片-->
          <v-col cols="12">
            <v-card
                class="mx-auto rounded-xl"
            >
              <v-list-item>
                <v-list-item-avatar
                    rounded
                    size="80"
                >
                  <v-img
                      :src="user.avatar"
                      :alt="user.name"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="user.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="user.description"></v-list-item-subtitle>
                  <v-card
                      class="mx-auto rounded-xl"
                      elevation="0">
                    <v-rating
                        length="5"
                        :value="user.score"
                    ></v-rating>
                    <v-overlay
                        absolute
                        opacity="0"
                        value="true"
                    >
                    </v-overlay>
                  </v-card>
                </v-list-item-content>
                <v-list-item-action>
                  <UserFollow
                      :follow-state="user.follow"
                      :index="0"
                      @result="user.follow=!user.follow"
                      :u-uid="user.uuid">
                  </UserFollow>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <!--应用信息-->
          <v-col cols="12">
            <v-card
                class="mx-auto rounded-xl"
                @click="$router.push('/packages/'+ packageInfo.id +'/info')"
            >
              <v-list-item>
                <v-list-item-avatar
                    rounded
                >
                  <v-img
                      :src="packageInfo.logo"
                      :alt="packageInfo.name"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="packageInfo.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="packageInfo.packageName"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <!--                  <PackageFollow-->
                  <!--                      :follow-state="user.follow"-->
                  <!--                      :index="0"-->
                  <!--                      @result="user.follow=!user.follow"-->
                  <!--                      :u-uid="user.uuid">-->
                  <!--                  </PackageFollow>-->
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <!--规则信息（信道，过滤范围，动作...）-->
          <v-col cols="12">
            <v-card
                class="mx-auto rounded-xl"
            >
              <v-card-title>

                <v-row>
                  <v-col class="d-flex align-center">规则信息</v-col>
                  <v-col class="align-center justify-end d-flex">
                    <v-btn
                        text
                        disabled
                        v-if="!rule.buy"
                    >
                      <v-icon>mdi-currency-usd</v-icon>
                      <span v-text="rule.price">0</span>
                    </v-btn>

                    <rule-follow :buy="rule.buy" :r-uid="rule.rUid" index="0" @resultBuy="updateRuleBuy"></rule-follow>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-expansion-panels
                  v-model="panel"
                  multiple
              >
                <!--基础信息-->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    基础信息
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p
                        v-text="rule.ruleName"
                        class="text-h6"
                    ></p>
                    <p
                        v-text="rule.ruleDescription"
                        class="text-body-2"
                    ></p>
                    <v-row>
                      <v-col>版本：{{ rule.versionCode }}</v-col>
                    </v-row>
                    <v-card
                        class="mx-auto rounded-xl"
                        elevation="0">
                      评分：
                      <v-rating
                          length="5"
                          :value="rule.score"
                      ></v-rating>
                      <v-overlay
                          absolute
                          opacity="0"
                          value="true"
                      >
                      </v-overlay>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--通知渠道-->
                <v-expansion-panel v-if="rule.channelIds!==undefined">
                  <v-expansion-panel-header>
                    通知渠道
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-chip
                        v-for="item in rule.channelIds"
                        :key="item"
                        filter
                        class="mt-2 mb-2 ms-3 me-3"
                    >
                      {{ item }}
                    </v-chip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--规则范围-->
                <v-expansion-panel v-if="rule.range!==undefined">
                  <v-expansion-panel-header>
                    规则范围
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="rule.event.length!==0">
                    <v-chip
                        v-for="item in ranges"
                        :key="item.value"
                        :input-value="rule.range.includes(item.value)"
                        filter
                        class="mt-2 mb-2 ms-3 me-3"
                    >
                      {{ item.name }}
                    </v-chip>

                    <div
                        v-if="rule.range.includes(0)"
                    >
                      <span
                          class="text-subtitle-2"
                          v-show="rule.buy"
                      >标题规则：</span>
                      <p
                          v-text="rule._ruleTitle"
                      ></p>
                    </div>

                    <div
                        v-if="rule.range.includes(1)"
                    >
                      <span
                          class="text-subtitle-2"
                          v-show="rule.buy"
                      >内容规则：</span>
                      <p
                          v-text="rule._ruleContent"
                      ></p>
                    </div>

                    <div
                        v-if="rule.range.includes(3)"
                    >
                      <span
                          class="text-subtitle-2"
                          v-show="rule.buy"
                      >按钮规则：</span>
                      <p v-text="rule._ruleButton"></p>
                    </div>

                    <div
                        class="d-flex justify-center"
                        v-if="!rule.buy"
                    >
                      <v-icon>mdi-lock</v-icon>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--执行动作-->
                <v-expansion-panel v-if="rule.event!==undefined">
                  <v-expansion-panel-header>
                    执行动作
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="!rule.event.includes(-1)">
                    <v-chip
                        v-for="item in events"
                        :key="item.value"
                        :input-value="rule.event.includes(item.value)"
                        class="mt-2 mb-2 ms-3 me-3"
                        filter
                    >
                      <v-icon
                          left
                      >
                        {{ 'mdi-' + item.icon }}
                      </v-icon>
                      {{ item.name }}
                    </v-chip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--其他-->
                <v-expansion-panel v-if="rule.event!==undefined">
                  <v-expansion-panel-header>
                    其他
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="rule.event.length!==0">
                    <v-row>
                      <v-col cols="12">
                        <!--输出标题-->
                        <div v-if="isShowOutput&&(rule.range.includes(0))">
                          <span class="text-subtitle-2">输出标题：</span>
                          <p v-text="rule.output.outputTitle"></p>
                        </div>
                        <!--输出内容-->
                        <div v-if="isShowOutput&&(rule.range.includes(1))">
                          <span class="text-subtitle-2">输出内容：</span>
                          <p v-text="rule.output.outputContent"></p>
                        </div>
                      </v-col>
                      <!--点击通知-->
                      <v-col cols="12" v-if="rule.event.includes(2)"
                      >
                        <span>点击通知：</span>
                        <v-row>
                          <v-col class="text-subtitle-2" cols="12">延迟点击（s）：{{ rule.clickNotification.time }}</v-col>
                        </v-row>
                      </v-col>
                      <!--点击按钮-->
                      <v-col cols="12"
                             v-if="rule.event.includes(7)"
                      >
                        <span>点击按钮：</span>
                        <v-row>
                          <v-col class="text-subtitle-2" cols="12">延迟点击（s）：{{ rule.clickButton.time }}</v-col>
                        </v-row>
                        <div>
                          <v-chip
                              v-for="button in rule.clickButton.buttonGroup.split(' ')"
                              :key="button"
                              filter
                              class="mt-2 mb-2 ms-3 me-3"
                          >
                            {{ button }}
                          </v-chip>
                        </div>
                      </v-col>
                      <!--打开应用-->
                      <v-col cols="12"
                             v-if="rule.event.includes(8)"
                      >
                      <span
                          class="text-subtitle-2"
                      >打开应用：</span>
                        <div>
                          <v-chip
                              v-for="packageInfo in rule.openApps"
                              :key="packageInfo.id"
                              class="mt-2 mb-2 ms-3 me-3"
                          >
                            <v-avatar

                                left
                            >
                              <v-img
                                  :src="packageInfo.logo"
                                  :alt="packageInfo.name"></v-img>
                            </v-avatar>
                            {{ packageInfo.name }}
                          </v-chip>
                        </div>
                      </v-col>
                      <!--弹幕通知-->
                      <v-col cols="12"
                             v-if="rule.event.includes(10)"
                      >
                        <span class="text-subtitle-2">弹幕通知：</span>
                        <p class="mb-0">弹幕位置：</p>
                        <v-chip
                            v-for="item in barrage"
                            :key="item.value"
                            :input-value="config.barragePosition===item.value"
                            filter
                            class="mt-2 mb-2 ms-3 me-3"
                        >
                          {{ item.name }}
                        </v-chip>
                        <p class="mb-0">滚动速度：{{ rule.barrage.speed }}</p>
                        <p class="mb-0">显示时间（s）：{{ rule.barrage.time }}</p>
                      </v-col>
                      <!--自定义铃声-->
                      <v-col cols="12"
                             v-if="rule.event.includes(11)"
                      >
                        <span>自定义铃声：</span>
                        <p class="mb-0">铃声：{{ rule.ringtone.path }}</p>
                        <p class="mb-0">播放次数（s）：{{ rule.ringtone.count }}</p>
                      </v-col>
                      <!--自定义震动-->
                      <v-col cols="12"
                             v-if="rule.event.includes(12)"
                      >
                      <span
                          class="text-subtitle-2"
                      >自定义震动：</span>
                        <p v-text="rule.vibration.time"></p>
                      </v-col>
                      <!--分享到应用-->
                      <v-col cols="12"
                             v-if="rule.event.includes(13)"
                      >
                        <span class="text-subtitle-2">分享到应用：</span>
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pb-0">应用：</v-col>
                              <v-col class="pt-0 pb-0  d-flex align-center justify-end">
                                <v-chip v-if="rule.shareToApp.package!==undefined">
                                  <v-avatar

                                      left
                                  >
                                    <v-img
                                        :src="rule.shareToApp.package.logo"
                                        :alt="rule.shareToApp.package.name"></v-img>
                                  </v-avatar>
                                  {{ rule.shareToApp.package.name }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pt-0 pb-0">URI：</v-col>
                              <v-col
                                  class="pt-0 pb-0  d-flex align-center justify-end"
                                  v-text="rule.shareToApp.uri"
                              >
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pt-0 pb-0">路径：</v-col>
                              <v-col
                                  class="pt-0 pb-0  d-flex align-center justify-end"
                                  v-text="rule.shareToApp.path"
                              >
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pt-0 pb-0">配置：</v-col>
                              <v-col
                                  class="pt-0 d-flex align-center justify-end"
                                  v-text="rule.shareToApp.config"
                              >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--分享到服务器-->
                      <v-col cols="12"
                             v-if="rule.event.includes(14)"
                      >
                      <span
                          class="text-subtitle-2"
                      >分享到服务器：</span>
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pb-0">方法：</v-col>
                              <v-col
                                  class="pb-0 d-flex align-center justify-end"
                                  v-text="rule.shareToServer.method"
                              ></v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pt-0 pb-0">URL：</v-col>
                              <v-col
                                  class="pt-0 pb-0 d-flex align-center justify-end"
                                  v-text="rule.shareToServer.url"
                              ></v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pt-0 pb-0">请求头配置：</v-col>
                              <v-col
                                  class="pt-0 pb-0 d-flex align-center justify-end"
                                  v-text="rule.shareToServer.headConfig"
                              ></v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col class="pt-0 pb-0">请求体配置：</v-col>
                              <v-col
                                  class="pt-0 d-flex align-center justify-end"
                                  v-text="rule.shareToServer.bodyConfig"
                              >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--其他-->
                      <v-col cols="12">
                        <p class="mb-0">延迟执行（s）：{{ rule.delayRun }}</p>
                        <p class="mb-0">执行时间段：</p>
                        <v-chip-group
                            v-if="rule.runTimeRanges!==''">
                          <v-chip
                              v-for="time in rule.runTimeRanges.split(' ')"
                              :key="time"
                              class="mt-2 mb-2 ms-3 me-3"
                          >
                            {{ time }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!--通知列表-->
      <v-col cols="12" md="6">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-card-title>
            测试通知
          </v-card-title>
          <v-divider class="ml-5 mr-5"></v-divider>
          <notification
              v-for="(notification,index) in rule.testNotificationList"
              :key="index"
              :data="notification"></notification>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Notification from "../../components/Notification";
import ranges from "../../data/ranges";
import events from "../../data/events";
import barrage from '../../data/barrage';
import {rule} from "../../request/api";
import UserFollow from "../../components/UserFollow";
import RuleFollow from "../../components/RuleFollow";

export default {
  name: "Info",
  components: {UserFollow, RuleFollow, Notification},
  data: () => ({
    ranges,
    events,
    barrage,
    isShowOutput: true,
    panel: [0, 1, 2, 3, 4],
    user: {},
    packageInfo: {},
    rule: {},
    config: {},
  }),
  methods: {
    /**
     * 购买规则。
     */
    updateRuleBuy(data) {
      this.rule.buy = data.buy
      rule(this.$route.params.uid).then(response => {
        const result = response.data.data
        this.user = result.user
        this.rule = result.rule
        if (result.rule.config !== undefined) {
          this.config = JSON.parse(result.rule.config)
        }
      })
    },
  },
  mounted() {
    rule(this.$route.params.uid).then(response => {
      const data = response.data.data
      console.log(data);
      this.user = data.user
      this.packageInfo = data.package
      this.rule = data.rule
      if (this.rule.config !== undefined) {
        this.config = JSON.parse(this.rule.config)
      }
      if (this.rule.testNotificationList !== undefined) {
        [...this.rule.testNotificationList].forEach(notification => {
          if (this.rule.channelIds.indexOf(notification.channelId) !== -1) {
            notification.channelId = `<strong class="deep-purple--text text-break">${notification.channelId}</strong>`
          }
          const title = notification.title.match(new RegExp(this.rule._ruleTitle));
          if (title !== null) {
            notification.title = notification.title.replace(title[0], `<strong class="deep-purple--text text-break">${title[0]}</strong>`)
          }

          const content = notification.content.match(new RegExp(this.rule._ruleContent));
          if (content !== null) {
            notification.content = notification.content.replace(content[0], `<strong class="deep-purple--text text-break">${content[0]}</strong>`)
          }

          const buttonGroup = []
          notification.buttonGroup.forEach(button => {
            buttonGroup.push({
              text: button,
              filter: new RegExp(this.rule._button).test(button)
            })
          })
          notification.buttonGroup = buttonGroup
        })
      }
    })
  }
}
</script>

<style scoped>

</style>