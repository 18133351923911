<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-list>
          <v-subheader>仅在用户页面生效</v-subheader>
          <v-list-item-group>
            <v-list-item @click="updateUserInfo">
              <v-list-item-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-row>
                  <v-col class="d-flex justify-start align-center">
                    <v-list-item-title>公开我的信息</v-list-item-title>
                  </v-col>
                  <v-col class="d-flex justify-end align-center">
                    <v-switch :input-value="privacyConfig.showUserInfo"></v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                :disabled="!privacyConfig.showUserInfo"
                @click="updateRule">
              <v-list-item-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-row>
                  <v-col class="d-flex justify-start align-center">
                    <v-list-item-title>公开我的规则</v-list-item-title>
                  </v-col>
                  <v-col class="d-flex justify-end align-center">
                    <v-switch
                        :input-value="privacyConfig.showRules"
                        :disabled="!privacyConfig.showUserInfo">
                    </v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                :disabled="!privacyConfig.showUserInfo"
                @click="updateFollowUser">
              <v-list-item-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-row>
                  <v-col class="d-flex justify-start align-center">
                    <v-list-item-title>公开我的关注用户</v-list-item-title>
                  </v-col>
                  <v-col class="d-flex justify-end align-center">
                    <v-switch
                        :input-value="privacyConfig.showFollowUsers"
                        :disabled="!privacyConfig.showUserInfo">
                    </v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                :disabled="!privacyConfig.showUserInfo"
                @click="updateFollowPackage">
              <v-list-item-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-row>
                  <v-col class="d-flex justify-start align-center">
                    <v-list-item-title>公开我的关注应用</v-list-item-title>
                  </v-col>
                  <v-col class="d-flex justify-end align-center">
                    <v-switch
                        :input-value="privacyConfig.showFollowPackages"
                        :disabled="!privacyConfig.showUserInfo">
                    </v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                :disabled="!privacyConfig.showUserInfo"
                @click="updateFan">
              <v-list-item-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-row>
                  <v-col class="d-flex justify-start align-center">
                    <v-list-item-title>公开我的粉丝</v-list-item-title>
                  </v-col>
                  <v-col class="d-flex justify-end align-center">
                    <v-switch
                        :input-value="privacyConfig.showFans"
                        :disabled="!privacyConfig.showUserInfo">
                    </v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {updateUserPrivacy, userPrivacy} from "../../request/api";

export default {
  name: "Privacy",
  data: () => ({
    status: false,
    privacyConfig: {},
  }),
  methods: {
    updateUserInfo() {
      this.privacyConfig.showUserInfo = !this.privacyConfig.showUserInfo
      updateUserPrivacy(this.privacyConfig)
    },
    updateRule() {
      this.privacyConfig.showRules = !this.privacyConfig.showRules
      updateUserPrivacy(this.privacyConfig)
    },
    updateFollowUser() {
      this.privacyConfig.showFollowUsers = !this.privacyConfig.showFollowUsers
      updateUserPrivacy(this.privacyConfig)
    },
    updateFollowPackage() {
      this.privacyConfig.showFollowPackages = !this.privacyConfig.showFollowPackages
      updateUserPrivacy(this.privacyConfig)
    },
    updateFan() {
      this.privacyConfig.showFans = !this.privacyConfig.showFans
      updateUserPrivacy(this.privacyConfig)
    },

  },
  created() {
    userPrivacy().then(response => {
      this.privacyConfig = response.data.data
      setTimeout(() => {
        this.status = true
      }, 100)
    })
  },

}
</script>

<style scoped>

</style>