<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
            v-model="code"
            outlined
            label="兑换码"
            clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
            rounded
            large
            class="me-16"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="query()"
        >
          查询
        </v-btn>
        <v-btn
            rounded
            class="ms-16"
            large
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="exchange()"
        >
          兑换
        </v-btn>

      </v-col>
      <v-col v-if="showInfo">
        <tbody>
        <tr>
          <td>赞助值：</td>
          <td v-text="value"></td>
        </tr>
        <tr>
          <td>会员天数：</td>
          <td v-text="day"></td>
        </tr>
        </tbody>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {codeExchange, codeExchangeQuery} from "../../request/api";

export default {
  name: "",
  data: () => ({
    showInfo: false,
    code: "",
    value: 0,
    day: 0,
    loading: false,
  }),
  methods: {
    query: function () {
      this.loading = true
      codeExchangeQuery(this.code).then(response => {
        const data = response.data.data
        this.loading = false
        this.showInfo = true
        this.value = data.giftAmount
        this.day = data.memberDay
      }).catch(()=>{
        this.loading = false
      })
    },
    exchange: function () {
      this.loading = true
      codeExchange(this.code).then(response => {
        const data = response.data.data
        this.loading = false
        this.showInfo = true
        this.value = data.giftAmount
        this.day = data.memberDay
        this.$store.state.snackbar = true
        this.$store.state.snackbarText = "兑换成功！"
      }).catch(()=>{
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>