<template>
  <v-container>
    <v-tabs
        center-active
        @change="selectedTab"
    >
      <v-tab>
        <v-icon left>
          mdi-star-outline
        </v-icon>
        关注
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-tag-outline
        </v-icon>
        推荐
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-sort-descending
        </v-icon>
        排行
      </v-tab>
      <!--关注-->
      <v-tab-item>
        <v-container>
          <v-row
              class="mt-3 d-flex justify-end">
            <v-chip
                input-value="true"
                filter
                class="me-3"
            >用户
            </v-chip>
            <v-chip
                input-value="true"
                filter
                class="me-3"
            >应用
            </v-chip>
          </v-row>
        </v-container>
        <v-container
            class="mt-3"
        >
          <!--内容区-->
          <div>
            <v-sheet
                v-if="follows.loading"
            >
              <v-row v-for="i in 2" :key="i">
                <v-col v-for="j in 4" :key="j">
                  <v-skeleton-loader
                      class="mx-auto"
                      type="card"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-sheet>

            <v-row
                class="mb-2"
            >
              <v-col
                  cols="12"
                  md="6"
                  lg="3"
                  v-for="(rule,index) in follows.list"
                  :key="index+Math.random()"
              >
                <rule-card :rule="rule"></rule-card>
              </v-col>
            </v-row>

            <NoData v-if="follows.loading===false&&follows.list.length===0"></NoData>
          </div>
          <!--换页-->
          <div class="text-center">
            <v-pagination
                v-if="follows.loading&&follows.list.length>0"
                v-model="follows.page.index"
                :length="follows.page.length"
                :total-visible="7"
            ></v-pagination>
          </div>
        </v-container>
      </v-tab-item>
      <!--推荐-->
      <v-tab-item>
        <v-container class="mt-3">
          <!--规则-->
          <v-row>
            <v-col cols="8">
              <h4
                  class="text-h5 text-sm-h4"
                  v-text="recommend[0].title"
              ></h4>
            </v-col>
            <v-col
                class="d-flex justify-end align-center"
            >
              <v-btn
                  icon
                  :to="'rules/gather/'+recommend[0].type"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
                lg="3"
                v-for="(rule,index) in recommend[0].list"
                :key="index+Math.random()"
            >
              <v-card
                  outlined
                  class="mx-auto rounded-xl"
              >
                <v-list-item three-line>
                  <v-list-item-content
                      @click="$router.push('/rules/'+ rule.rUid +'/info')"
                  >
                    <v-list-item-title v-text="rule.ruleName"></v-list-item-title>
                    <v-list-item-subtitle v-text="rule.ruleDescription">
                    </v-list-item-subtitle>
                    <v-card elevation="0">
                      <v-rating
                          :value="rule.star"
                          disabled="true"
                          length="5"
                      ></v-rating>
                      <v-overlay
                          absolute
                          value="true"
                          opacity="0"/>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <!--用户-->
          <v-row>
            <v-col cols="8">
              <h4
                  class="text-h5 text-sm-h4"
                  v-text="recommend[1].title"
              ></h4>
            </v-col>
            <v-col
                class="d-flex justify-end align-center"
            >
              <v-btn
                  icon
                  :to="'rules/gather/'+recommend[1].type"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
                lg="3"
                v-for="(user,index) in recommend[1].list"
                :key="index+Math.random()"
            >
              <v-card
                  class="mx-auto rounded-xl"
                  outlined>
                <v-list-item three-line>
                  <v-list-item-avatar
                      @click="$router.push('/users/'+user.uuid+'/info')"
                      size="45"
                  >
                    <v-img
                        :src="user.avatar"
                        :alt="user.name"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content
                      @click="$router.push('/users/'+user.uuid+'/info')"
                  >
                    <v-list-item-title v-text="user.name"></v-list-item-title>
                    <v-list-item-subtitle v-if="user.description!==''"
                                          v-text="user.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <!--应用-->
          <v-row>
            <v-col cols="8">
              <h4
                  class="text-h5 text-sm-h4"
                  v-text="recommend[2].title"
              ></h4>
            </v-col>
            <v-col
                class="d-flex justify-end align-center"
            >
              <v-btn
                  icon
                  :to="'rules/gather/'+recommend[2].type"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
                lg="3"
                v-for="(packageInfo,index) in recommend[2].list"
                :key="index+Math.random()"
            >
              <v-card
                  outlined
                  class="mx-auto rounded-xl"
                  @click="$router.push('/packages/'+packageInfo.id+'/info')"
              >
                <v-list-item three-line>
                  <v-list-item-avatar
                      tile
                      size="80"

                  >
                    <v-img :src="packageInfo.logo"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        class="text-h5 mb-1"
                        v-text="packageInfo.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-text="packageInfo.packageName"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <!--排行-->
      <v-tab-item>
        <!--规则-->
        <v-container
            v-for="(item,index) in sort[0]"
            :key="index+Math.random()">
          <v-row>
            <v-col cols="8">
              <h4
                  class="text-h5 text-sm-h4"
                  v-text="item.title"
              ></h4>
            </v-col>
            <v-col
                class="d-flex justify-end align-center"
            >
              <v-btn
                  icon
                  :to="'rules/gather/'+item.type"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
                lg="3"
                v-for="(rule,index) in item.list"
                :key="index+Math.random()"
            >
              <v-card
                  outlined
                  class="mx-auto rounded-xl"
              >
                <v-list-item three-line>
                  <v-list-item-content
                      @click="$router.push('/rules/'+ rule.rUid +'/info')"
                  >
                    <v-list-item-title v-text="rule.ruleName"></v-list-item-title>
                    <v-list-item-subtitle v-text="rule.ruleDescription">
                    </v-list-item-subtitle>
                    <v-card elevation="0">
                      <v-rating
                          :value="rule.star"
                          disabled="true"
                          length="5"
                      ></v-rating>
                      <v-overlay
                          absolute
                          value="true"
                          opacity="0"/>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!--用户-->
        <v-container
            v-for="(item,index) in sort[1]"
            :key="index+Math.random()">
          <v-row>
            <v-col cols="8">
              <h4
                  class="text-h5 text-sm-h4"
                  v-text="item.title"
              ></h4>
            </v-col>
            <v-col
                class="d-flex justify-end align-center"
            >
              <v-btn
                  icon
                  :to="'rules/gather/'+item.type"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
                lg="3"
                v-for="(user,index) in item.list"
                :key="index+Math.random()"
            >
              <v-card
                  class="mx-auto rounded-xl"
                  outlined>
                <v-list-item three-line>
                  <v-list-item-avatar
                      @click="$router.push('/users/'+user.uuid+'/info')"
                      size="45"
                  >
                    <v-img
                        :src="user.avatar"
                        :alt="user.name"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content
                      @click="$router.push('/users/'+user.uuid+'/info')"
                  >
                    <v-list-item-title v-text="user.name"></v-list-item-title>
                    <v-list-item-subtitle v-if="user.description!==''"
                                          v-text="user.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!--应用-->
        <v-container
            v-for="(item,index) in sort[2]"
            :key="index+Math.random()">
          <v-row>
            <v-col cols="8">
              <h4
                  class="text-h5 text-sm-h4"
                  v-text="item.title"
              ></h4>
            </v-col>
            <v-col
                class="d-flex justify-end align-center"
            >
              <v-btn
                  icon
                  :to="'rules/gather/'+item.type"
              >
                <v-icon>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
                lg="3"
                v-for="(packageInfo,index) in item.list"
                :key="index+Math.random()"
            >
              <v-card
                  outlined
                  class="mx-auto rounded-xl"
                  @click="$router.push('/packages/'+packageInfo.id+'/info')"
              >
                <v-list-item three-line>
                  <v-list-item-avatar
                      tile
                      size="80"

                  >
                    <v-img :src="packageInfo.logo"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        class="text-h5 mb-1"
                        v-text="packageInfo.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-text="packageInfo.packageName"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import RuleCard from "../components/RuleCard";
import NoData from "../components/NoData";
import {
  rankingPackageFollow,
  rankingPackageRule,
  rankingRuleBuy,
  rankingRuleScore, rankingUserFollow, rankingUserRule, rankingUserScore,
  recommendPackage,
  recommendRule,
  recommendUser,
  userFollows
} from "../request/api";

export default {
  name: 'Home',
  components: {RuleCard, NoData},
  data: () => ({
    follows: {
      type: 3,
      list: [],
      loading: true,
      page: {
        "length": 1,
        "index": 1,
      },
    },
    recommend: [
      {
        "title": "值得订阅（规则）",
        "type": "buy",
        list: []
      },
      {
        "title": "值得关注（用户）",
        "type": "user",
        list: []
      },
      {
        "title": "值得关注（应用）",
        "type": "package",
        list: []
      },
    ],
    sort: [
      [{
        "title": "评分最高（规则）",
        "type": "ruleScore",
        list: []
      },
        {
          "title": "订阅最多（规则）",
          "type": "ruleBuy",
          list: []
        }],
      [{
        "title": "评分最高（用户）",
        "type": "userScore",
        list: []
      },
        {
          "title": "规则最多（用户）",
          "type": "userRule",
          list: []
        },
        {
          "title": "关注最多（用户）",
          "type": "userRule",
          list: []
        }],
      [{
        "title": "规则最多（应用）",
        "type": "packageRule",
        list: []
      },
        {
          "title": "关注最多（应用）",
          "type": "packageFollow",
          list: []
        }],
    ],
  }),
  methods: {
    selectedTab(index) {
      switch (index) {
        case 1:
          this.loadRecommends()
          break;
        case 2:
          this.loadRankings()
          break;
        default:
          this.loadUserFollows()
          break;
      }
    },
    loadUserFollows() {
      userFollows({
        "type": this.follows.type,
        "page": this.follows.page.index,
        "count": 30
      }).then(response => {
        this.follows.loading = false
        this.follows.list = response.data.data.list
        this.follows.page.length = response.data.meta.last_page
      }).catch(() => {
        this.follows.loading = false
      })
    },
    loadRecommends() {
      recommendRule({
        "count": 4,
      }).then(response => {
        this.recommend[0].list = response.data.data.list
      })
      recommendUser({
        "count": 4,
      }).then(response => {
        this.recommend[1].list = response.data.list
      })
      recommendPackage({
        "count": 4,
      }).then(response => {
        this.recommend[2].list = response.data.data.list
      })
    },
    loadRankings() {
      // 规则
      rankingRuleScore({
        "count": 4,
      }).then(response => {
        this.sort[0][0].list = response.data.data.list
      })
      rankingRuleBuy({
        "count": 4,
      }).then(response => {
        this.sort[0][1].list = response.data.data.list
      })
      // 用户
      rankingUserScore({
        "count": 4,
      }).then(response => {
        console.log(response);
        this.sort[1][0].list = response.data.list
      })
      rankingUserRule({
        "count": 4,
      }).then(response => {
        this.sort[1][1].list = response.data.list
      })
      rankingUserFollow({
        "count": 4,
      }).then(response => {
        this.sort[1][2].list = response.data.list
      })
      // 应用
      rankingPackageRule({
        "count": 4,
      }).then(response => {
        this.sort[2][0].list = response.data.data.list
      })
      rankingPackageFollow({
        "count": 4,
      }).then(response => {
        this.sort[2][1].list = response.data.data.list
      })
    },
  },
}
</script>
