<template>
  <v-sheet
      class="pa-10"
  >
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-img
            :src="require('../assets/undraw_no_data_re_kwbl.svg')"
            max-width="300"
        >
        </v-img>
      </v-col>
      <v-col cols="12">
        <p class="text-h4 text-center">
          找不到数据！
        </p>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "NoData"
}
</script>

<style scoped>

</style>