<template>
  <v-card
      class="mx-auto rounded-xl"
  >
    <v-card-title>
      <v-avatar
          style="cursor:pointer;"
          size="32"
          @click="$router.push('/packages/'+ ruleInfo.package.id +'/info')"
      >
        <img
            :src="ruleInfo.package.logo"
            :alt="ruleInfo.package.packageName"
        >
      </v-avatar>
      <span
          class="text-h6 ms-3 font-weight-light"
          style="cursor:pointer;"
          @click="$router.push('/packages/'+ ruleInfo.package.id +'/info')"
          v-text="ruleInfo.package.name"
      ></span>
    </v-card-title>
    <v-card-text
        class="text-h5 font-weight-bold pb-0"
        v-text="ruleInfo.ruleName"
        style="cursor:pointer;"
        @click="$router.push('/rules/'+ ruleInfo.rUid +'/info')"
    >
    </v-card-text>
    <v-card-subtitle
        v-text="ruleInfo.ruleDescription"
        style="cursor:pointer;"
        @click="$router.push('/rules/'+ ruleInfo.rUid +'/info')"
    >
    </v-card-subtitle>
    <v-card-actions>
      <v-list-item class="grow">
        <v-list-item-avatar
            color="grey darken-3"
            style="cursor:pointer;"
            @click="$router.push('/users/'+ ruleInfo.user.uuid +'/info')"
        >
          <v-img
              class="elevation-6"
              :alt="ruleInfo.user.name"
              :src="ruleInfo.user.avatar"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content
            style="cursor:pointer;"
            @click="$router.push('/users/'+ ruleInfo.user.uuid +'/info')"
        >
          <v-list-item-title
              v-text="ruleInfo.user.name"
          >
          </v-list-item-title>
        </v-list-item-content>

        <v-row
            align="center"
            justify="end"
        >
          <v-icon class="mr-1">
            mdi-currency-usd
          </v-icon>
          <span
              class="subheading mr-2"
              v-text="ruleInfo.price"
          ></span>
          <span class="mr-1">·</span>
          <v-icon class="mr-1">
            mdi-star
          </v-icon>
          <span class="subheading"
                v-text="ruleInfo.star"
          ></span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RuleCard",
  props: ["rule"],
  data: () => ({
    ruleInfo: {}
  }),
  created() {
    this.ruleInfo = this.$props.rule;
  }
}
</script>

<style scoped>

</style>