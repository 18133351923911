<template>
  <v-container>
    <v-row>
      <!--应用信息-->
      <v-col cols="12" md="6" xl="4">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-list-item three-line>
            <v-list-item-avatar
                tile
                size="80"

            >
              <v-img
                  :src="packageData.logo"
              >

              </v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                  class="text-h5 mb-1"
                  v-text="packageData.name"
              >
              </v-list-item-title>
              <v-list-item-subtitle
                  v-text="packageData.packageName"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
                outlined
                rounded
                v-if="!packageData.follow"
                text
                @click="followPackage()"
            >
              关注应用
            </v-btn>
            <v-btn
                outlined
                rounded
                v-if="packageData.follow"
                text
                @click="unfollowPackage()"
            >
              取消关注
            </v-btn>
<!--            <v-btn-->
<!--                outlined-->
<!--                rounded-->
<!--                text-->
<!--                @click="$router.push('/rules/create')"-->
<!--            >-->
<!--              创建规则-->
<!--            </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-col>
      <!--关注用户-->
      <v-col cols="12" md="6" xl="4">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-card-title>
            关注用户
          </v-card-title>
          <v-card-text>
            <v-avatar
                class="ma-1"
                size="75"

                v-for="user in followUsers"
                :key="user.uuid"
                @click="$router.push('/users/'+ user.uuid +'/info')"
            >
              <img
                  :src="user.avatar"
                  :alt="user.name"
              >
            </v-avatar>
            <v-avatar
                class="ma-1"
                color="primary"
                v-if="followUsers.length>=5"
                size="75"
            >
              <v-icon color="white">mdi-dots-horizontal</v-icon>
            </v-avatar>
            <v-avatar
                class="ma-1"
                size="75"

                v-if="followUsers.length<=0"
            >
              无
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!--热度-->
      <v-col cols="12" style="display: none" xl="4">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-card-title>
            热度
          </v-card-title>
          <v-card-text>
            <v-sparkline
                :labels="labels"
                :value="value"
                line-width="2"
                smooth="100"
                padding="16"
            ></v-sparkline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!--优秀创作者-->
      <v-col cols="12" md="6">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-card-title>
            优秀创作者
          </v-card-title>
          <v-divider class="ml-5 mr-5"></v-divider>
          <div v-for="(user,i) in topUser" :key="i">
            <v-list-item three-line>
              <v-list-item-avatar
                  @click="$router.push('/users/'+ user.uuid +'/info')"
                  size="45"
                  >
                <v-img
                    :src="user.avatar"
                    :alt="user.name"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content
                  @click="$router.push('/users/'+ user.uuid +'/info')"
              >
                <v-list-item-title v-text="user.name"></v-list-item-title>
                <v-list-item-subtitle v-if="user.description!==''" v-text="user.description"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <UserFollow
                    :followState="user.follow"
                    :uUid="user.uuid"
                    :index="i"
                    @result="updateUserFollow"
                ></UserFollow>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset class="mr-5"></v-divider>
          </div>
        </v-card>
      </v-col>
      <!--优秀规则-->
      <v-col cols="12" md="6">
        <v-card
            class="mx-auto rounded-xl"
        >
          <v-card-title>
            优秀规则
          </v-card-title>
          <v-divider class="ml-5 mr-5"></v-divider>
          <div v-for="(rule,i) in topRule" :key="i">
            <v-list-item three-line>
              <v-list-item-content
                  @click="$router.push('/rules/'+ rule.rUid +'/info')"
              >
                <v-list-item-title v-text="rule.ruleName"></v-list-item-title>
                <v-list-item-subtitle v-text="rule.ruleDescription">
                </v-list-item-subtitle>
                <v-card elevation="0">
                  <v-rating
                      :value="rule.star"
                      disabled="true"
                      length="5"
                  ></v-rating>
                  <v-overlay
                      absolute
                      value="true"
                      opacity="0"/>
                </v-card>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                    text
                    disabled
                >
                  <v-icon>mdi-currency-usd</v-icon>
                  <span v-text="rule.price">0</span>
                </v-btn>

                <rule-follow :buy="rule.buy" :r-uid="rule.rUid" :index="i" @resultBuy="updateRuleBuy"></rule-follow>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="ml-5 mr-5"></v-divider>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  packageFollow,
  packageFollowUser,
  packageInfo,
  packageTopRule,
  packageTopUser,
  packageUnFollow,
  userFollow,
  userUnFollow
} from "../../request/api";
import UserFollow from "../../components/UserFollow";
import RuleFollow from "../../components/RuleFollow";

export default {
  name: "Info",
  components: {RuleFollow, UserFollow},
  data: () => ({
    errorText: "",
    packageData: {},
    followUsers: [],
    topUser: [],
    topRule: [],
    labels: [
      '12am',
      '3am',
      '6am',
      '9am',
      '12pm',
      '3pm',
      '6pm',
      '9pm',
    ],
    value: [
      200,
      675,
      10010,
      390,
      310,
      460,
      250,
      240,
    ],
  }),
  methods: {

    /**
     * 加载应用基本信息。
     */
    loadPackageInfo() {
      packageInfo(this.$route.params.uid).then(response => {
        this.packageData = response.data.data
      })
    },

    /**
     * 加载关注用户信息。
     */
    loadFollowUser() {
      packageFollowUser(this.$route.params.uid).then(response => {
        this.followUsers = response.data.data.list
      })
    },

    /**
     * 加载优秀创作者。
     */
    loadTopUser() {
      packageTopUser(this.$route.params.uid).then(response => {
        this.topUser = response.data.data
      })
    },

    /**
     * 加载优秀规则。
     */
    loadTopRule() {
      packageTopRule(this.$route.params.uid).then(response => {
        this.topRule = response.data.data
      })
    },

    /**
     * 关注应用。
     */
    followPackage() {
      packageFollow(this.$route.params.uid).then(() => {
        this.loadFollowUser()
        this.packageData.follow = !this.packageData.follow
      })
    },

    /**
     * 取消关注应用。
     */
    unfollowPackage() {
      packageUnFollow(this.$route.params.uid).then(() => {
        this.loadFollowUser()
        this.packageData.follow = !this.packageData.follow
      })
    },

    /**
     * 关注用户。
     */
    followUser(uuid, index) {
      userFollow(uuid).then(() => {
        this.topUser[index]["follow"] = !this.topUser[index]["follow"]
      })
    },

    /**
     * 取消关注用户。
     */
    unfollowUser(uuid, index) {
      userUnFollow(uuid).then(() => {
        this.topUser[index]["follow"] = !this.topUser[index]["follow"]
      })
    },

    /**
     * 更新用户关注状态。
     * @param data
     */
    updateUserFollow(data) {
      this.topUser[data.index]["follow"] = data.follow
    },

    /**
     * 更新规则订阅状态。
     * @param data
     */
    updateRuleBuy(data) {
      this.topRule[data.index]["buy"] = data.buy
    },
  },
  created() {
    this.loadPackageInfo()
    this.loadFollowUser()
    this.loadTopUser()
    this.loadTopRule()
  }
}
</script>

<style scoped>

</style>