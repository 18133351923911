<template>
  <v-container
      class="pb-16 mt-4"
  >
    <!--菜单/标题栏-->
    <v-row>
      <v-col
          cols="8"
      >
        <h4
            class="text-h5 text-sm-h4"
        >应用列表</h4>
      </v-col>
      <v-col
          class="d-flex justify-end align-center"
      >
        <v-btn
            icon
            @click="updatePage()"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-menu
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>
                mdi-sort
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider
        class="mt-3 mb-3"
    ></v-divider>

    <!--内容区-->
    <div>
      <v-sheet
          v-if="loading"
      >
        <v-row>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                max-height="180"
                type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row
          class="mb-2"
      >
        <v-col
            cols="12"
            md="6"
            lg="3"
            v-for="item in packages"
            :key="item.id"
        >
          <v-card
              class="mx-auto rounded-xl"
              style="cursor:pointer;"
              @click="$router.push('/packages/'+ item.id +'/info')"
          >
            <v-list-item three-line>
              <v-list-item-avatar
                  tile
                  size="80"
              >
                <v-img
                    :src="item.logo"
                >

                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                    class="text-h5 mb-1"
                    v-text="item.name"
                >
                </v-list-item-title>
                <v-list-item-subtitle
                    v-text="item.packageName"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                class="grow"
            >
              <v-row
                  align="center"
                  class="pl-4"
              >
                <v-icon class="mr-1">
                  mdi-code-tags
                </v-icon>
                <span
                    class="subheading mr-2"
                    v-text="item.ruleCount"
                ></span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-plus-circle-outline
                </v-icon>
                <span class="subheading"
                      v-text="item.followCount"
                ></span>
              </v-row>
            </v-list-item>

          </v-card>
        </v-col>
      </v-row>

      <NoData v-if="loading===false&&packages.length===0"></NoData>
    </div>

    <!--换页-->
    <div
        class="text-center"
        v-show="!loading&&packages.length>0"
    >
      <v-pagination
          v-model="page.index"
          :length="page.length"
          :total-visible="7"
          @input="updatePage()"
      ></v-pagination>
    </div>

    <v-btn
        fab
        fixed
        right
        @click="dialog = !dialog"
        style="bottom: 80px"
    >
      <v-icon>
        mdi-magnify
      </v-icon>
    </v-btn>

    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title
            class="text-h5"
        >
          搜索
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                  :items="type"
                  label="类型"></v-select>
            </v-col>
            <v-col>
              <v-text-field></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="dialog = false"
          >
            确定
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import {packageList} from "../../request/api";
import NoData from "../../components/NoData";

export default {
  name: "List",
  components: {NoData},
  data: () => ({
    dialog: false,
    loading: true,
    page: {
      "length": 1,
      "index": 1,
    },
    type: ["应用名称", "应用包名"],
    items: [
      {title: '综合升序', value: ''},
      {title: '综合降序', value: ''},
      {title: '规则升序', value: ''},
      {title: '规则降序', value: ''},
      {title: '关注升序', value: ''},
      {title: '关注降序', value: ''},
      {title: '应用名称升序', value: ''},
      {title: '应用名称降序', value: ''},
      {title: '应用包名升序', value: ''},
      {title: '应用包名降序', value: ''},
    ],
    packages: []
  }),
  methods: {
    selectItem: (value) => {
      console.log(value);
    },
    list() {
      packageList("*",0,"name",0,{
        "page": this.page.index,
        "count": 12,
      })
          .then(response => {
            this.loading = false
            this.packages = response.data.data.list
            this.page.length = response.data.meta.last_page
          })
          .catch(() => {
            this.loading = false
          })
    },
    updatePage() {
      this.loading = true
      this.packages = []
      this.list()
    }
  },
  mounted() {
    this.list()
  }
}
</script>

<style scoped>

</style>