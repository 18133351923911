<template>
  <v-container
      class="mt-16"
      fluid
  >
    <v-row>
      <v-col
          class="ma-auto"
          sm="10"
          md="8"
          lg="7"
          xl="6"
      >
        <v-alert
            type="error"
            dark
            dismissible
            text
            v-for="(value,key) in errors"
            v-bind:key="key"
            v-text="value[0]"
        ></v-alert>

        <v-card class="pb-8 ps-8 pe-8">
          <v-card-title>
            登录
          </v-card-title>
          <validation-observer
              ref="observer"
              v-slot="{ invalid }"
          >

            <form @submit.prevent="submit">
              <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email">
                <v-text-field
                    v-model="from.email"
                    :error-messages="errors"
                    label="邮箱"
                    required
                ></v-text-field>
              </validation-provider>

              <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required|max:16|min:6">
                <v-text-field
                    @click:append="togglePasswordShowState"
                    :append-icon="passwordShowState?'mdi-eye':'mdi-eye-off'"
                    v-model="from.password"
                    :error-messages="errors"
                    label="密码"
                    :type="passwordShowState?'text':'password'"
                    counter
                    required
                ></v-text-field>
              </validation-provider>
            </form>


            <v-btn
                class="mr-4"
                type="submit"
                :disabled="invalid?invalid:loading"
                @click="submit"
            >
              <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              <span v-text="loading?'':'登录'"></span>
            </v-btn>

            <v-btn @click="clear">
              清空
            </v-btn>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {email, max, min, regex, required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import {userLogin} from "../../request/api";


setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} 不能为空',
})

extend('max', {
  ...max,
  message: '{_field_} 不得大于 {length} 个字符',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} 不匹配 {regex}',
})

extend('email', {
  ...email,
  message: '电子邮件必须有效',
})

extend('min', {
  ...min,
  message: '{_field_} 不得小于 {length} 个字符',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  name: "Login",
  data: () => ({
    passwordShowState: false,
    from: {
      "login_type": 0,
      "email": "",
      "password": "",
      "deviceUuid": require("md5")(encodeURI(navigator.appVersion)),
      "deviceName": navigator.appVersion,
    },
    errors: {},
    loading: false,
  }),
  methods: {
    submit() {
      this.errors = {}
      this.loading = true
      userLogin(this.from)
          .then(res => {
            this.$store.state.user = res.data.data
            localStorage.setItem("user", JSON.stringify(res.data.data))
            this.loading = false
            this.$router.back()
          })
          .catch(() => {
            this.loading = false
          })
    },
    clear() {
      this.from.email = ''
      this.from.password = ''
      this.$refs.observer.reset()
    },
    togglePasswordShowState() {
      this.passwordShowState = !this.passwordShowState
    },
  },
}
</script>

<style scoped>

</style>