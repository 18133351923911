<template>
  <v-container>
    <NoData v-if="userLoading===false&&users.length===0"></NoData>
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="(user,index) in users"
            :key="user.rUid"
        >
          <v-card
              class="mx-auto rounded-xl"
              outlined>
            <v-list-item three-line>
              <v-list-item-avatar
                  @click="$router.push('/users/'+user.uuid+'/info')"
                  size="45"
              >
                <v-img
                    :src="user.avatar"
                    :alt="user.name"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content
                  @click="$router.push('/users/'+user.uuid+'/info')"
              >
                <v-list-item-title v-text="user.name"></v-list-item-title>
                <v-list-item-subtitle v-if="user.description!==''"
                                      v-text="user.description"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <UserFollow
                    :followState="user.follow"
                    :uUid="user.uuid"
                    :index="index"
                    @result="updateUserFollowUser"
                ></UserFollow>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <!--换页-->
          <div class="text-center">
            <v-pagination
                v-if="users.length>0"
                v-model="userPage.index"
                :length="userPage.length"
                :total-visible="7"
                @input="loadUserFans()"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import {userFollowFans} from "../../request/api";
import UserFollow from "../../components/UserFollow";
import NoData from "../../components/NoData";

export default {
  name: "Fans",
  components: {
    UserFollow,
    NoData,
  },
  data: () => ({
    userLoading: false,
    users: [],
    userPage: {
      "length": 1,
      "index": 1,
    },
  }),
  methods: {
    loadUserFans() {
      userFollowFans(this.$store.state.user.uuid, {
        "page": this.userPage.index,
        "count": 15,
      }).then(response => {
        this.userLoading = false
        this.users = response.data.data.list
        this.userPage.length = response.data.meta.last_page
      }).catch(() => {
        this.userLoading = false
      })
    },
    updateUserFollowUser(data) {
      this.users[data.index].follow = data.follow
    },
  },
  created() {
    this.loadUserFans()
  }
}
</script>

<style scoped>

</style>