<template>
  <v-container
      class="pt-8">
    <!--用户信息和用户评分-->
    <v-row>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/edit')">
            <v-list-item three-line>
              <v-list-item-avatar
                  tile
                  size="130"
              >
                <v-img
                    :src="$store.state.user.avatar"
                    :alt="$store.state.user.name"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  我的信息
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ $store.state.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.user.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0">
            <v-overlay
                absolute
                opacity="0"
                value="true"
            ></v-overlay>
            <v-list-item three-line>
              <v-list-item-content
                  class="pt-7 pb-7"
              >
                <v-list-item-title
                    class="text-h3 d-flex justify-center"
                >
                  <v-rating
                      size="32"
                      :value="score"
                      length="5"
                  ></v-rating>
                </v-list-item-title>
                <v-list-item-subtitle
                    class="text-h5 d-flex justify-center mt-4"
                >
                  评分
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <!--用户金额和会员剩余天数-->
    <v-row>
      <v-col cols="12" :sm="memberDate!==-1?6:12">
        <v-card
            elevation="0"
            color="primary"
            class="mx-auto rounded-xl"
            dark
            @click="$router.push('/my/transaction')"
        >
          <v-list-item three-line>
            <v-list-item-content
                class="pt-7 pb-7"
            >
              <v-list-item-title
                  class="text-h3 d-flex justify-center"
                  v-text="balance+' F'"
              >
                ---- F
              </v-list-item-title>
              <v-list-item-subtitle
                  class="text-h5 d-flex justify-center mt-4"
              >
                当前金额
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" v-if="memberDate!==-1">
        <v-card
            class="mx-auto rounded-xl"
            elevation="0"
            @click="$router.push('/my/vip')">
          <v-list-item three-line>
            <v-list-item-content
                class="pt-7 pb-7"
            >
              <v-list-item-title
                  class="text-h3 d-flex justify-center"
              >
                <span v-text="memberDate"></span> 天
              </v-list-item-title>
              <v-list-item-subtitle
                  class="text-h5 d-flex justify-center mt-4"
              >
                会员
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <!--我的规则，我的关注，我的订阅，我的粉丝-->
    <v-row>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/rule')">
            <v-list-item three-line>
              <v-list-item-content
              >
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="2">
                      <v-icon large>
                        mdi-code-tags
                      </v-icon>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-center align-center">
                      <span class="text-h5">我的规则</span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-icon large>
                        mdi-chevron-right
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-list-item-title
                    class="text-h3 d-flex justify-center mt-4 mb-4"
                    v-text="ruleCount"
                >
                  --
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/follow')">
            <v-list-item three-line>
              <v-list-item-content
              >
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="2">
                      <v-icon large>
                        mdi-star-outline
                      </v-icon>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-center align-center">
                      <span class="text-h5">我的关注</span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-icon large>
                        mdi-chevron-right
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-list-item-title
                    class="text-h3 d-flex justify-center mt-4 mb-4"
                    v-text="followCount"
                >
                  --
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/buy')">
            <v-list-item three-line>
              <v-list-item-content
              >
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="2">
                      <v-icon large>
                        mdi-plus-circle-outline
                      </v-icon>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-center align-center">
                      <span class="text-h5">我的订阅</span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-icon large>
                        mdi-chevron-right
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-list-item-title
                    class="text-h3 d-flex justify-center mt-4 mb-4"
                    v-text="buyCount"
                >
                  --
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/fans')">
            <v-list-item three-line>
              <v-list-item-content
              >
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="2">
                      <v-icon large>
                        mdi-account-group-outline
                      </v-icon>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-center align-center">
                      <span class="text-h5">我的粉丝</span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-icon large>
                        mdi-chevron-right
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-list-item-title
                    class="text-h3 d-flex justify-center mt-4 mb-4"
                    v-text="fans"
                >
                  --
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <!--浏览历史，隐私设置-->
    <v-row>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/exchange')">
            <v-list-item three-line>
              <v-list-item-subtitle>
                <v-row>
                  <v-col cols="2">
                    <v-icon large>
                      mdi-swap-horizontal
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="d-flex justify-center align-center">
                    <span class="text-h5">兑换码</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              class="mx-auto rounded-xl"
              elevation="0"
              @click="$router.push('/my/privacy')">
            <v-list-item three-line>
              <v-list-item-subtitle>
                <v-row>
                  <v-col cols="2">
                    <v-icon large>
                      mdi-security
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="d-flex justify-center align-center">
                    <span class="text-h5">隐私设置</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card
              elevation="0"
              class="mx-auto rounded-xl"
              @click="$router.push('/my/code')">
            <v-list-item three-line>
              <v-list-item-subtitle>
                <v-row>
                  <v-col cols="2">
                    <v-icon large>
                      mdi-tag-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="d-flex justify-center align-center">
                    <span class="text-h5">邀请码</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6">
        <v-hover>
          <v-card elevation="0" @click="$router.push('/my/devices')">
            <v-list-item three-line>
              <v-list-item-subtitle>
                <v-row>
                  <v-col cols="2">
                    <v-icon large>
                      mdi-cellphone-link
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="d-flex justify-center align-center">
                    <span class="text-h5">设备管理</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {userInfo} from "../../request/api";

export default {
  name: "My",
  data: () => ({
    memberDate: 0,
    score: 0,
    balance: 0,
    ruleCount: 0,
    followCount: 0,
    buyCount: 0,
    fans: 0
  }),
  methods: {
    myInfo() {
      userInfo(this.$store.state.user.uuid).then(response => {
        const info = response.data.data
        this.memberDate = info.memberDate
        this.score = info.score
        this.balance = info.balance
        this.ruleCount = info.ruleCount
        this.followCount = info.followCount
        this.buyCount = info.buyCount
        this.fans = info.fans
      }).catch((error) => {
        console.log(error);
      })
    }
  },
  created() {
    if (this.$store.state.user.token === undefined) {
      this.$router.push("/login")
    }
    this.myInfo()
  }
}
</script>

<style scoped>

</style>