import Index from "../views/packages/Index";
import Info from "../views/packages/Info";
import List from "../views/packages/List";

export default {
    path: "/packages",
    component: Index,
    children: [
        {
            path: '/',
            name: '应用',
            component: List
        },
        {
            path: ':uid/info',
            name: '应用信息',
            component: Info
        }
    ]
};