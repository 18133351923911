import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from "../views/error/NotFound";
import Login from "../views/users/Login";
import rules from "./rules";
import packages from "./packages";
import users from "./users";
import my from "./my";
import tools from "./tools";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '热门',
        component: Home,
    },
    packages,
    rules,
    users,
    my,
    tools,
    {
        path: '/about',
        name: '关于',
        // 路由级代码分割
        // 这会为此路由生成一个单独的块（about.[hash].js）
        // 这是在访问路由时延迟加载的。
        component: () => import(/* webpackChunkName: "关于" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: '登录',
        component: Login
    },
    {
        path: "*",
        name: "Not Found Page",
        component: NotFound
    }
]

const router = new VueRouter({
    routes
})

export default router
