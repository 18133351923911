<template>
  <div
      class="pt-2 pb-2 ps-3 pe-3"
  >
    <div
        class="d-flex align-center justify-space-between"
    >
      <div v-if="notificationInfo.package!==undefined" class="d-flex align-center">
        <v-avatar
            size="20"
            color="grey darken-2"
        >
          <v-img
              :src="notificationInfo.package.logo"
              :alt="notificationInfo.package.name"/>
        </v-avatar>

        <span
            class="text-sm-caption ms-1 text--primary"
            v-text="notificationInfo.package.name"
        >应用名称</span>
        <span
            class="text-sm-body-2 ml-1 mr-1"
        >·</span>
        <span
            class="text-sm-caption me-1 text--secondary"
        >1秒前</span>
      </div>
    </div>

    <div
        class="pt-3">
      <div
          class="d-flex"
      >
        <div
            style="width: 100%;"
        >
          <div
              style="line-height:initial;"
              v-html="notificationInfo.title"
          >我是通知标题
          </div>
          <div
              class="text-sm-caption text--secondary"
              style="line-height: initial"
              v-html="'渠道:'+notificationInfo.channelId"
          >
            渠道：{{ notificationInfo.channelId }}
          </div>
          <div
              class="text-sm-caption text--secondary"
              style="line-height: initial"
              v-html="notificationInfo.content"
          >
            我是通知内容
          </div>
        </div>
      </div>
    </div>
    <v-card
        elevation="0">
      <v-chip-group>
        <v-chip
            v-for="(item,key) in notificationInfo.buttonGroup"
            :key="key"
            :input-value="item.filter"
            filter
        >
          {{ item.text }}
        </v-chip>
      </v-chip-group>
      <v-overlay
          absolute
          opacity="0"/>
    </v-card>

  </div>
</template>

<script>

export default {
  name: "Notification",
  props: ["data"],
  data: () => ({
    notificationInfo: {},
  }),
  mounted() {
    this.notificationInfo = this.$props.data
  }
}
</script>

<style scoped lang="sass">
#buttonList
  background-color: blueviolet
</style>