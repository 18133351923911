import RuleIndex from "../views/rules/Index";
import RuleCreate from "../views/rules/Create";
import RuleInfo from "../views/rules/Info";
import RuleGather from "../views/rules/Gather";
import RuleList from "../views/rules/List";

export default {
    path: '/rules',
    name: '规则',
    component: RuleIndex,
    children: [
        {
            path: '/',
            name: '规则',
            component: RuleList,
        },
        {
            path: 'create',
            name: '创建规则',
            component: RuleCreate,
        },
        {
            path: 'gather/:type',
            name: '规则集合',
            component: RuleGather,
        },
        {
            path: ':uid/info',
            name: '规则信息',
            component: RuleInfo,
        }
    ]
};