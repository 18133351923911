export default [
    {
        "name": "滚动",
        "value": 0
    },
    {
        "name": "固定顶部",
        "value": 1
    },
    {
        "name": "固定底部",
        "value": 2
    }
]