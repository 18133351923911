<template>
  <v-container id="container" fluid>
    <v-row
        class="fill-height pa-3 pa-sm-0"
    >
      <v-col
          md="4"
          lg="3"
          class="hidden-sm-and-down"
          style="position: relative"

      >
        <v-card
            ref="notificationContainer"
            class="overflow-auto mt-10"
            style="height: 85vh;"
        >
          <div v-for="(item,key) in notificationList" :key="key">
            <NotificationItem
                :data="item"
                :index="key"
                @removeNotification="removeNotification"
                @updateNotification="updateNotification"
            ></NotificationItem>
            <v-divider></v-divider>
          </div>
        </v-card>
        <v-fab-transition>
          <v-btn
              color="deep-purple mt-10"
              dark
              absolute
              top
              left
              fab
              @click="addNotification"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
      <v-col
          md="8"
          lg="8"
          sm="12"
          offset-lg="1"
          offset-md="0"
      >
        <validation-observer
            ref="observer"
            v-slot="{ invalid }"
        >
          <form @submit.prevent="submit">
            <v-card>
              <v-card-title>
                创建规则
              </v-card-title>
              <v-expansion-panels
                  v-model="panel"
                  multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    基础信息
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <v-switch
                            v-model="form.useState"
                            label="规则使用状态"
                        ></v-switch>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="7"
                      >
                        <validation-provider
                            v-slot="{ errors }"
                            name="规则名称"
                            rules="required|max:35">
                          <v-text-field
                              label="规则标题"
                              v-model="form.title"
                              :counter="35"
                              :error-messages="errors"
                              outlined
                              messages="该文本框会根据您选择【规则范围，执行动作，其他】生成对于的内容。"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <validation-provider
                        v-slot="{ errors }"
                        name="规则描述"
                        rules="required|max:1000">
                      <v-textarea
                          label="规则描述"
                          v-model="form.content"
                          :error-messages="errors"
                          :counter="1000"
                          outlined
                          messages="该文本框会根据您选择【规则范围，执行动作，其他】生成对于的内容。"
                      ></v-textarea>
                    </validation-provider>

                    <v-row>
                      <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="规则版本"
                            rules="required|max:5"
                        >
                          <v-text-field
                              label="规则版本"
                              type="number"
                              :counter="5"
                              :error-messages="errors"
                              v-model="form.ruleVersion"
                              outlined
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col>
                        <v-text-field
                            label="规则作者"
                            outlined
                            value="admin"
                            disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--通知信道-->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    通知信道
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-chip
                        v-for="item in channel"
                        :key="item.value"
                        :input-value="form.channel.has(item.value)"
                        filter
                        @click="selectChannel(item.value)"
                        class="mt-2 mb-2 ms-3 me-3"
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--规则范围-->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    规则范围
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-chip
                        v-for="item in ranges"
                        :key="item.value"
                        :input-value="form.range===item.value"
                        filter
                        @click="form.range=item.value"
                        class="mt-2 mb-2 ms-3 me-3"
                    >
                      {{ item.name }}
                    </v-chip>

                    <v-divider
                        v-show="form.range!==-1"
                        class="mt-2 mb-5"
                    ></v-divider>

                    <v-row
                        v-if="form.range===0||form.range===2"
                    >
                      <v-col
                          cols="12"
                          sm="6"
                          class="hidden-md-and-up"
                      >
                        <v-textarea
                            label="测试标题"
                            counter="5000"
                            outlined
                            v-model="form.testTitle"
                            :messages="testTitleMessage"
                        ></v-textarea>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="12"
                          lg="12"
                      >
                        <validation-provider
                            v-slot="{ errors }"
                            name="标题规则"
                            rules="required|max:500|ruleIsTrue">
                          <v-textarea
                              label="标题规则"
                              :counter="500"
                              outlined
                              :error-messages="errors"
                              v-model="form.ruleTitle"
                              placeholder="支持正则表达式和关键词"
                          ></v-textarea>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row
                        v-if="form.range===1||form.range===2"
                    >
                      <v-col
                          cols="12"
                          sm="6"
                          class="hidden-md-and-up"
                      >
                        <v-textarea
                            label="测试内容"
                            counter="5000"
                            outlined
                            v-model="form.testContent"
                            :messages="testContentMessage"
                        ></v-textarea>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="12"
                          lg="12"
                      >
                        <validation-provider
                            v-slot="{ errors }"
                            name="内容规则"
                            rules="required|max:500|ruleIsTrue">
                          <v-textarea
                              label="内容规则"
                              :counter="500"
                              outlined
                              :error-messages="errors"
                              v-model="form.ruleContent"
                              placeholder="支持正则表达式和关键词"
                          ></v-textarea>
                        </validation-provider>

                      </v-col>
                    </v-row>

                    <v-row
                        v-if="form.range===3"
                    >
                      <v-col
                          cols="12"
                          sm="6"
                          class="hidden-md-and-up"
                      >
                        <v-textarea
                            label="测试按钮"
                            counter="1000"
                            outlined
                            v-model="form.testButton"
                            :messages="testButtonMessage"
                        ></v-textarea>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="12"
                          lg="12"
                      >
                        <validation-provider
                            v-slot="{ errors }"
                            name="按钮规则"
                            rules="required|max:100|ruleIsTrue">
                          <v-textarea
                              label="按钮规则"
                              :counter="100"
                              outlined
                              :error-messages="errors"
                              v-model="form.ruleButton"
                              placeholder="支持正则表达式和关键词"
                          ></v-textarea>
                        </validation-provider>

                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--执行动作-->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    执行动作
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-chip
                        v-for="item in events"
                        :key="item.value"
                        :input-value="form.events.has(item.value)"
                        filter
                        @click="selectEvent(item.value)"
                        class="mt-2 mb-2 ms-3 me-3"
                    >
                      <v-icon
                          left
                      >
                        {{ 'mdi-' + item.icon }}
                      </v-icon>
                      {{ item.name }}
                    </v-chip>

                    <v-divider
                        v-show="form.range!==-1||isShowSelect||isShowShareApp||isShowShareServer"
                        class="mt-2 mb-5"
                    ></v-divider>
                    <!--输出标题-->
                    <validation-provider
                        v-if="isShowOutput&&(form.range===0||form.range===2)"
                        v-slot="{ errors }"
                        name="输出标题"
                        rules="required|max:5000">
                      <v-textarea
                          label="输出标题"
                          outlined
                          :counter="5000"
                          v-model="form.outputTitle"
                          :error-messages="errors"
                          :messages="outputTitleMessage"
                          value="##"
                      ></v-textarea>
                    </validation-provider>
                    <!--输出内容-->
                    <validation-provider
                        v-if="isShowOutput&&(form.range===1||form.range===2)"
                        v-slot="{ errors }"
                        name="输出内容"
                        rules="required|max:5000">
                      <v-textarea
                          label="输出内容"
                          outlined
                          :counter="5000"
                          :error-messages="errors"
                          v-model="form.outputContent"
                          :messages="outputContentMessage"
                          value="##"
                      ></v-textarea>
                    </validation-provider>
                    <!--选择应用-->
                    <validation-provider
                        v-if="isShowSelect"
                        v-slot="{ errors }"
                        name="选择应用"
                        rules="required"
                    >
                      <v-autocomplete
                          v-model="form.selectPackage"
                          :items="packageList"
                          outlined
                          chips
                          label="选择应用"
                          item-text="name"
                          item-value="name"
                          :error-messages="errors"
                          multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removePackage(data.item)"
                          >
                            <v-avatar left>
                              <v-img :src="data.item.avatar"></v-img>
                            </v-avatar>
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-avatar>
                              <v-img :src="data.item.avatar"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                    <!--发送到某个应用-->
                    <div
                        v-if="isShowShareApp"
                    >
                      <p>发送到其他应用</p>
                      <validation-provider
                          v-slot="{ errors }"
                          name="选择目标应用"
                          rules="required"
                      >
                        <v-autocomplete
                            v-model="form.selectShareApp"
                            :items="packageList"
                            outlined
                            chips
                            label="选择目标应用"
                            item-text="name"
                            item-value="name"
                            :error-messages="errors"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removePackage(data.item)"
                            >
                              <v-avatar left>
                                <v-img :src="data.item.avatar"></v-img>
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-avatar>
                                <v-img :src="data.item.avatar"></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </validation-provider>
                      <validation-provider
                          v-slot="{ errors }"
                          name="发送到某个应用"
                          rules="required"
                      >
                        <v-row v-for="(value,key) in form.shareApp" :key="key">
                          <v-col cols="12" sm="3">
                            <v-text-field
                                outlined
                                label="属性："
                                v-model="value.key"
                                :error-messages="errors">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-text-field
                                outlined
                                label="值："
                                v-model="value.value"
                                :error-messages="errors">
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-btn-toggle>
                              <v-btn
                                  icon
                                  @click="removeShareItem(form.shareApp,value)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                  icon
                                  @click="addShareItem(form.shareApp)"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </validation-provider>
                    </div>
                    <!--发送到服务器-->
                    <div
                        v-if="isShowShareServer"
                    >
                      <p>基础信息</p>
                      <v-row>
                        <v-col cols="12" sm="3">
                          <validation-provider
                              v-slot="{ errors }"
                              name="方法"
                              rules="required">
                            <v-select
                                outlined
                                label="方法"
                                :items="methods"
                                :error-messages="errors"
                            >
                            </v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <validation-provider
                              v-slot="{ errors }"
                              name="URL"
                              rules="required"
                          >
                            <v-text-field
                                outlined
                                label="URL"
                                :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <p>请求头</p>
                      <validation-provider
                          v-slot="{ errors }"
                          name="请求头"
                          rules="required"
                      >
                        <v-row v-for="(value,key) in form.shareServer.header" :key="key">
                          <v-col cols="12" sm="3">
                            <v-text-field
                                outlined
                                label="属性："
                                v-model="value.key"
                                :error-messages="errors">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-text-field
                                outlined
                                label="值："
                                v-model="value.value"
                                :error-messages="errors">
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-btn-toggle>
                              <v-btn
                                  icon
                                  @click="removeShareItem(form.shareServer.header,value)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                  icon
                                  @click="addShareItem(form.shareServer.header)"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </validation-provider>

                      <p>数据</p>
                      <validation-provider
                          v-slot="{ errors }"
                          name="数据"
                          rules="required"
                      >
                        <v-row v-for="(value,key) in form.shareServer.data" :key="key">
                          <v-col cols="12" sm="3">
                            <v-text-field
                                outlined
                                label="属性："
                                v-model="value.key"
                                :error-messages="errors">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-text-field
                                outlined
                                label="值："
                                v-model="value.value"
                                :error-messages="errors">
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-btn-toggle>
                              <v-btn
                                  icon
                                  @click="removeShareItem(form.shareServer.data,value)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                  icon
                                  @click="addShareItem(form.shareServer.data)"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </validation-provider>
                    </div>


                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--其他-->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    其他
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-chip
                        :input-value="form.isGlobal"
                        filter
                        @click="form.isGlobal=!form.isGlobal"
                    >全局规则
                    </v-chip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-card-actions>
                <v-btn
                    class="mr-4"
                    type="submit"
                    text
                    :disabled="invalid"
                >
                  保存
                </v-btn>
                <v-btn
                    text
                >
                  清空
                </v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </validation-observer>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {max, required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import NotificationItem from '../../components/Notification'
import eventData from '../../data/events';
import ranges from '../../data/ranges';

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} 不能为空',
})

extend('max', {
  ...max,
  message: '{_field_} 不得大于 {length} 个字符',
})
/**
 * 检测用户输入的正则表达式是正确。
 */
extend('ruleIsTrue', value => {
  try {
    new RegExp(value)
    return true
  } catch (e) {
    return "{_field_} 正则表达式错误"
  }
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    NotificationItem,
  },
  name: "Create",
  data: () => ({
    notificationList: [],
    panel: [0, 1, 2, 3, 4, 5],
    isShowOutput: false,
    isShowSelect: false,
    isShowShareApp: false,
    isShowShareServer: false,
    channel: [
      {
        "name": "所有",
        "value": 0,
      },
      {
        "name": "test",
        "value": 1,
      },
      {
        "name": "tes2",
        "value": 2,
      },
    ],
    ranges,
    events: eventData,
    testTitleMessage: "",
    testContentMessage: "",
    testButtonMessage: "",
    outputTitleMessage: "",
    outputContentMessage: "",
    packageList: [
      {
        "name": "通知过滤",
        "packageName": "top.webb_l.notificationfilter",
        "avatar": "https://cdn.vuetifyjs.com/images/lists/4.jpg"
      },
      {
        "name": "通知过滤1",
        "packageName": "top.webb_l.notificationfilter",
        "avatar": "https://cdn.vuetifyjs.com/images/lists/4.jpg"
      },
      {
        "name": "通知过滤2",
        "packageName": "top.webb_l.notificationfilter",
        "avatar": "https://cdn.vuetifyjs.com/images/lists/4.jpg"
      },
      {
        "name": "通知过滤3",
        "packageName": "top.webb_l.notificationfilter",
        "avatar": "https://cdn.vuetifyjs.com/images/lists/4.jpg"
      },
      {
        "name": "通知过滤5",
        "packageName": "top.webb_l.notificationfilter",
        "avatar": "https://cdn.vuetifyjs.com/images/lists/4.jpg"
      },
    ],
    shareTemplate: {
      "key": "",
      "value": ""
    },
    scheme: ["http", "https",],
    methods: ["GET", "HEAD", "PUT", "POST", "OPTIONS", "DELETE",],
    form: {
      useState: true,
      title: "",
      content: "",
      ruleVersion: 0,
      authorId: 0,
      channel: new Set(),
      range: -1,
      testTitle: "",
      ruleTitle: "",
      testContent: "",
      ruleContent: "",
      testButton: "",
      ruleButton: "",
      events: new Set(),
      outputTitle: "##",
      outputContent: "##",
      selectPackage: [],
      shareApp: [],
      selectShareApp: [],
      shareServer: {
        scheme: "",
        methods: "",
        url: "",
        header: [],
        data: [],
      },
      isGlobal: false
    },
    /**
     * snackbar
     */
    multiLine: true,
    snackbar: false,
    text: ``,
  }),
  methods: {
    /**
     * 选择通知信道
     * @param value int
     */
    selectChannel(value) {
      let data = this.form.channel
      if (data.has(0)) {
        data.delete(0)
      }
      data.has(value) ? data.delete(value) : data.add(value)
      if (data.has(0)) {
        data.clear()
        data.add(0)
      }
      this.panel = [0, 1, 2, 3, 4, 5]
    },
    selectEvent(value) {
      let events = this.form.events
      events.has(value) ? events.delete(value) : events.add(value)
      this.panel = [0, 1, 2, 3, 4, 5]
      this.isShowSelect = false
      this.isShowOutput = false
      this.isShowShareApp = false
      this.isShowShareServer = false
      events.forEach(item => {
        if (this.events[item].isShowOutput) {
          this.isShowOutput = true
        }
        if (this.events[item].isShowSelect) {
          this.isShowSelect = true
        }
        if (this.events[item].isShowShareApp) {
          this.isShowShareApp = true
        }
        if (this.events[item].isShowShareServer) {
          this.isShowShareServer = true
        }
      })

      this.form.content = this.autoCreateDescription()
    },
    submit: () => {
    },
    /**
     * 自动生成规则描述内容。
     * @returns {string}
     */
    autoCreateDescription: function () {
      let formData = this.form
      let ruleRangeDescription
      switch (formData.range) {
        case 0:
          ruleRangeDescription = `通知标题包含了【${formData.ruleTitle}】`
          break;
        case 1:
          ruleRangeDescription = `通知内容包含了【${formData.ruleContent}】`
          break;
        case 2:
          ruleRangeDescription = `通知标题包含了【${formData.ruleTitle}】并且通知内容也包含了【${formData.ruleContent}】`
          break;
        default:
          ruleRangeDescription = `通知按钮包含了【${formData.ruleButton}】`
          break;
      }

      let ruleOutput
      switch (formData.range) {
        case 0:
          ruleOutput = `通知标题为【${formData.outputTitle}】`
          break;
        case 1:
          ruleOutput = `和通知内容为【${formData.outputContent}】`
          break;
        case 2:
          ruleOutput = `通知标题为【${formData.outputTitle}】和通知内容为【${formData.outputContent}】`
          break;
        default:
          break;
      }

      let outputDescription, events = [];
      for (let index of formData.events.values()) {
        let eventInfo = this.events[index]
        events.push(eventInfo.name)
        if (eventInfo.isShowOutput) {
          outputDescription = `并输出${ruleOutput === undefined ? "" : ruleOutput}。`
        }
      }

      return `规则生效范围是【${formData.range > -1 ? this.ranges[formData.range].name : ""}】。如果${ruleRangeDescription}就执行【${events}】事件。${outputDescription === undefined ? "" : outputDescription}该规则应用在【${formData.isGlobal ? "全部应用" : "通知过滤"}】。`
    },
    addNotification: function () {
      this.notificationList.push({
            packageName: "我是应用名称",
            title: "我是通知标题",
            regexTitle: "我是通知标题",
            content: "我是通知内容。\n点击右上角第一个按钮可以对我进行编辑。",
            regexContent: "我是通知内容。\n点击右上角第一个按钮可以对我进行编辑。",
            time: "现在",
            actions: []
          }
      )
    },
    updateNotification: function (notification) {
      this.notificationList[notification.index] = notification.data
    },
    removeNotification: function (index) {
      this.notificationList.splice(index, 1)
    },
    returnRegexResult: function (regexStr, text) {
      let array = []
      if (regexStr === "" || text === "") {
        return array
      }
      try {
        let regexp = new RegExp(regexStr);
        console.log(regexp.exec(text));
        return array
      } catch (e) {
        return array
      }
    },
    escapeHTML: function (str) {
      let tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
      }
      return str.replace(/[&<>]/g, tag => tagsToReplace[tag] || tag)
    },
    removePackage(item) {
      const index = this.form.selectPackage.indexOf(item.name)
      if (index >= 0) this.form.selectPackage.splice(index, 1)
    },
    addShareItem(data) {
      data.push(this.shareTemplate)
    },
    removeShareItem(data, item) {
      const startIndex = data.indexOf(item);
      if (startIndex !== -1) {
        if (data.length > 1) {
          data.splice(startIndex, 1)
        }
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.form.content = this.autoCreateDescription()
        this.testTitleMessage = this.returnRegexResult(this.form.ruleTitle, this.form.testTitle)
        this.testContentMessage = this.returnRegexResult(this.form.ruleContent, this.form.testContent)
        this.testButtonMessage = this.returnRegexResult(this.form.ruleButton, this.form.testButton)
        this.outputTitleMessage = this.form.outputTitle.replace("##", this.returnRegexResult(this.form.ruleTitle, this.form.testTitle))
        this.outputContentMessage = this.form.outputContent.replace("##", this.returnRegexResult(this.form.ruleContent, this.form.testContent))
        const that = this
        let regexResult
        this.notificationList.forEach((val) => {
          switch (that.form.range) {
            case 0:
              val.regexTitle = val.title
              regexResult = that.returnRegexResult(that.form.ruleTitle, val.title)
              regexResult.forEach(value => {
                val.regexTitle = val.regexTitle.replace(regexResult, `<string class='text-h6 deep-purple--text font-weight-black'>${that.escapeHTML(value)}</string>`)
              })
              break;
            case 1:
              val.regexContent = val.content
              regexResult = that.returnRegexResult(that.form.ruleContent, val.content)
              val.regexContent = val.regexContent.replace(regexResult, `<string class='text-h6 deep-purple--text font-weight-black'>${that.escapeHTML(regexResult)}</string>`)
              break;
            case 2:
              val.regexTitle = val.title
              regexResult = that.returnRegexResult(that.form.ruleTitle, val.title)
              val.regexTitle = val.regexTitle.replace(regexResult, `<string class='text-h6 deep-purple--text font-weight-black'>${that.escapeHTML(regexResult)}</string>`)

              val.regexContent = val.content
              regexResult = that.returnRegexResult(that.form.ruleContent, val.content)
              val.regexContent = val.regexContent.replace(regexResult, `<string class='text-h6 deep-purple--text font-weight-black'>${that.escapeHTML(regexResult)}</string>`)
              break;
            case 3:
              val.actions.forEach(action => {
                action.isFilter = that.returnRegexResult(that.form.ruleButton, action.text) !== ""
              })
              break;
            default:
              break;
          }
        })
        regexResult = ""
      },
      deep: true
    },
    "form.ruleVersion"(newVal, oldVal) {
      if (parseInt(newVal) < parseInt(oldVal)) {
        this.snackbar = true
        this.text = "规则版本 不能小于上一次的版本"
      }
    }
  },
  created() {
    this.$refs.notificationContainer.style.height = "100px";
  },
  mounted() {
    this.form.shareApp.push(this.shareTemplate)
    this.form.shareServer.header.push(this.shareTemplate)
    this.form.shareServer.data.push(this.shareTemplate)
  },
}
</script>

<style scoped lang="sass">
#container
  height: 100%

</style>