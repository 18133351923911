<template>
  <v-container
      class="pb-16 mt-4"
  >
    <!--菜单/标题栏-->
    <v-row>
      <v-col
          cols="8"
      >
        <h4
            class="text-h5 text-sm-h4"
        >规则列表</h4>
      </v-col>
      <v-col
          class="d-flex justify-end align-center"
      >
        <v-btn
            icon
            @click="updatePage()"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-menu
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>
                mdi-sort
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider
        class="mt-3 mb-3"
    ></v-divider>

    <!--内容区-->
    <div>
      <v-sheet
          v-if="loading"
      >
        <v-row>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
                class="mx-auto"
                type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>

      <v-row
          class="mb-2"
      >
        <v-col
            cols="12"
            md="6"
            lg="3"
            v-for="rule in rules"
            :key="rule.rUid"
        >
          <rule-card :rule="rule"></rule-card>
        </v-col>
      </v-row>

      <NoData v-if="loading===false&&rules.length===0"></NoData>
    </div>

    <!--换页-->
    <div class="text-center">
      <v-pagination
          v-if="!loading&&rules.length>0"
          v-model="page.index"
          :length="page.length"
          :total-visible="7"
          @input="updatePage()"
      ></v-pagination>
    </div>

    <v-btn
        fab
        fixed
        right
        @click="dialog = !dialog"
        style="bottom: 80px"
    >
      <v-icon>
        mdi-magnify
      </v-icon>
    </v-btn>

    <v-dialog
        v-model="dialog"
    >
      <v-card>
        <v-card-title
            class="text-h5"
        >
          搜索
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                  :items="type"
                  label="类型"></v-select>
            </v-col>
            <v-col>
              <v-text-field></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!--匹配模式-->
            <v-col cols="12">
              <p>匹配模式：</p>
              <v-chip
                  v-for="item in match"
                  :key="item.value"
                  filter

                  class="mt-2 mb-2 ms-3 me-3"
              >
                {{ item.name }}
              </v-chip>
            </v-col>
            <!--过滤范围-->
            <v-col cols="12">
              <p>过滤范围：</p>
              <v-chip
                  v-for="item in ranges"
                  :key="item.value"
                  filter

                  class="mt-2 mb-2 ms-3 me-3"
              >
                {{ item.name }}
              </v-chip>
            </v-col>
            <!--动作/事件-->
            <v-col cols="12">
              <p>动作：</p>
              <v-chip
                  v-for="item in events"
                  :key="item.value"
                  filter
                  class="mt-2 mb-2 ms-3 me-3"
              >
                <v-icon
                    left
                >
                  {{ 'mdi-' + item.icon }}
                </v-icon>
                {{ item.name }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="dialog = false"
          >
            确定
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import events from '../../data/events';
import ranges from '../../data/ranges';
import RuleCard from "../../components/RuleCard";
import {ruleList} from "../../request/api";
import NoData from "../../components/NoData";

export default {
  name: "List",
  components: {NoData, RuleCard},
  data: () => ({
    page: {
      "length": 1,
      "index": 1,
    },
    loading: true,
    dialog: false,
    type: ["规则名称", "规则描述", "应用名称", "应用包名"],
    items: [
      {title: '综合升序', value: ''},
      {title: '综合降序', value: ''},
      {title: '标题升序', value: ''},
      {title: '标题降序', value: ''},
      {title: '描述升序', value: ''},
      {title: '描述降序', value: ''},
      {title: '价格升序', value: ''},
      {title: '价格降序', value: ''},
      {title: '购买升序', value: ''},
      {title: '购买降序', value: ''},
      {title: '作者升序', value: ''},
      {title: '作者升序', value: ''},
      {title: '作者降序', value: ''},
      {title: '应用名称升序', value: ''},
      {title: '应用名称降序', value: ''},
      {title: '应用包名升序', value: ''},
      {title: '应用包名降序', value: ''},
    ],
    ranges,
    events,
    match: [
      {"name": "模糊匹配", "value": 0,},
      {"name": "自定义匹配", "value": 0,}
    ],
    rules: []
  }),
  methods: {
    list() {
      ruleList({
        "content": "",
        "contentType": 0,
        "orderColumn": "createdAt",
        "match": [0, 1],
        "orderType": 1,
        "ranges": [-1, 0, 1, 3],
        "events": [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      }, {
        "page": this.page.index,
        "count": 8,
      }).then(response => {
        this.loading = false
        this.rules = response.data.data.list
        this.page.length = response.data.meta.last_page
      }).catch(() => {
        this.loading = false
      })
    },
    updatePage() {
      this.loading = true
      this.list()
    }
  },
  mounted() {
    this.list()
  }
}
</script>

<style scoped>

</style>