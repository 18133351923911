<template>
  <v-card
      id="card"
      class="d-flex justify-center align-sm-center"
      outlined
      tile
  >
    <div class="mt-16 mt-sm-0 mb-sm-16">
      <v-img
          max-width="320"
          :src="notFoundImage"
          class="ma-auto"
      ></v-img>
      <div class="text-h2 text-md-h1 ma-auto transition-swing mt-3 text-center">Not Found</div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "NotFound",
  data: () => ({
    notFoundImage: require("../../assets/undraw_page_not_found_su7k.svg")
  })
}
</script>

<style scoped lang="sass">
#card
  height: 100%
</style>