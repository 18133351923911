<template>
  <v-app>
    <!--状态栏-->
    <v-app-bar
        color="deep-purple accent-4"
        app
        dark
        fixed
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon @click="$route.path==='/'?'':$router.back()">
        <v-icon v-if="$route.path!=='/'">mdi-arrow-left</v-icon>
        <v-icon v-if="$route.path==='/'">mdi-bell</v-icon>
      </v-app-bar-nav-icon>

      <!--状态栏标题-->
      <v-toolbar-title v-text="$route.name"></v-toolbar-title>
      <v-spacer></v-spacer>
      <!--主题栏右侧菜单-->
      <v-btn text @click="toLinkPage(downloadLink)">
        <v-icon>mdi-download</v-icon>
        <span class="hidden-xs-only">下载应用</span>
      </v-btn>

      <v-menu
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-tools</v-icon>
            工具
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, i) in toolItems"
              :key="i"
          >
            <v-list-item-title @click="$router.push(item.path)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

<!--      <v-menu
          bottom
          left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              :class="item.path==='/login'?'hidden-sm-and-up':''"
          >
            <v-list-item-title @click="$router.push(item.path)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>-->
    </v-app-bar>

    <v-main
        class="pb-16"
    >
      <v-snackbar
          v-model="$store.state.snackbar"
      >
        {{ $store.state.snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="$store.state.snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <router-view/>
    </v-main>

    <v-bottom-navigation
        fixed
        color="deep-purple"
        grow
    >
      <v-btn to="/">
        <span>热门</span>

        <v-icon>mdi-fire</v-icon>
      </v-btn>

      <v-btn to="/packages">
        <span>应用</span>

        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn to="/rules">
        <span>规则</span>

        <v-icon>mdi-code-tags</v-icon>
      </v-btn>

      <v-btn to="/my">
        <span>我的</span>

        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawerState: false,
    downloadLink: "https://www.coolapk.com/apk/top.webb_l.notificationfilter",
    items: [
      {title: 'Dashboard', icon: 'mdi-view-dashboard'},
      {title: 'Account', icon: 'mdi-account-box'},
      {title: 'Admin', icon: 'mdi-gavel'},
    ],
    menuItems: [
      {title: '登录', path: '/login'},
      {title: '关于', path: '/about'},
      {title: '创建', path: '/rules/create'},
    ],
    toolItems: [
      {title: '转换规则', path: '/tools/ruleSwitch'},
    ],
  }),

  methods: {
    /**
     * 根据url跳转到指定的网页。
     * @param url
     */
    toLinkPage: (url) => window.location.href = url,
  },
  created() {
    try {
      if (localStorage.getItem("user") !== null) {
        this.$store.state.user = JSON.parse(localStorage.getItem("user"))
      }
    }catch (e) {
      console.log(e);
    }
  }
};
</script>
