<template>
  <v-container fluid>
    <NoData v-if="ruleLoading===false&&rules.length===0"></NoData>
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="(rule,index) in rules"
            :key="rule.rUid"
        >
          <v-card
              outlined
              class="mx-auto rounded-xl"
          >
            <v-list-item three-line>
              <v-list-item-content
                  @click="$router.push('/rules/'+ rule.rUid +'/info')"
              >
                <v-list-item-title v-text="rule.ruleName"></v-list-item-title>
                <v-list-item-subtitle v-text="rule.ruleDescription">
                </v-list-item-subtitle>
                <v-card elevation="0">
                  <v-rating
                      :value="rule.star"
                      disabled="true"
                      length="5"
                  ></v-rating>
                  <v-overlay
                      absolute
                      value="true"
                      opacity="0"/>
                </v-card>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                    text
                    disabled
                >
                  <v-icon>mdi-currency-usd</v-icon>
                  <span v-text="rule.price">0</span>
                </v-btn>

                <rule-follow :buy="rule.buy" :r-uid="rule.rUid" :index="index"
                             @resultBuy="updateRuleBuy"></rule-follow>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <!--换页-->
          <div class="text-center">
            <v-pagination
                v-if="rules.length>0"
                v-model="rulePage.index"
                :length="rulePage.length"
                :total-visible="7"
                @input="loadRuleData()"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import {ruleUser} from "../../request/api";
import RuleFollow from "../../components/RuleFollow";
import NoData from "../../components/NoData";

export default {
  name: "Rule",
  components: {
    RuleFollow,
    NoData,
  },
  data: () => ({
    ruleLoading: false,
    rules: [],
    rulePage: {
      "length": 1,
      "index": 1,
    },
  }),
  methods: {
    loadRuleData() {
      ruleUser(this.$store.state.user.uuid, {
        "page": this.rulePage.index,
        "count": 15
      }).then(response => {
        this.ruleLoading = false
        this.rules = response.data.data.list
        this.rulePage.length = response.data.meta.last_page
      }).catch(() => {
        this.ruleLoading = false
      })
    },
    /**
     * 更新规则订阅状态。
     * @param data
     */
    updateRuleBuy(data) {
      this.rules[data.index]["buy"] = data.buy
    },
  },
  created() {
    this.loadRuleData()
  }
}
</script>

<style scoped>

</style>