<template>
  <div>
    <v-btn
        rounded
        depressed
        color="primary"
        v-if="!$props.followState"
        :loading="loading"
        @click="followUser()"
    >
      关注
    </v-btn>
    <v-btn
        rounded
        outlined
        color="primary"
        v-if="$props.followState"
        :loading="loading"
        @click="unfollowUser()"
    >
      已关注
    </v-btn>
  </div>
</template>

<script>
import {userFollow, userUnFollow} from "../request/api";

export default {
  name: "UserFollow",
  props: ["followState", "uUid", "index"],
  data: () => ({
    loading: false
  }),
  methods: {
    /**
     * 关注用户。
     */
    followUser() {
      this.loading = true
      userFollow(this.$props.uUid)
          .then(() => {
            this.result()
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
    },

    /**
     * 取消关注用户。
     */
    unfollowUser() {
      this.loading = true
      userUnFollow(this.$props.uUid)
          .then(() => {
            this.result()
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
    },

    /**
     * 返回关注或者取消关注的内容。
     */
    result() {
      this.$emit("result", {
        "follow": !this.$props.followState,
        "index": this.$props.index
      })
    },
  },
}
</script>

<style scoped>

</style>