import UserIndex from '../views/users/Index'
import UserInfo from '../views/users/Info'

export default {
    path: '/users',
    name: '用户',
    component: UserIndex,
    children: [
        {
            path: ':uid/info',
            name: '用户信息',
            component: UserInfo,
        }
    ]
};