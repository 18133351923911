<template>
  <v-card elevation="0">
    <v-btn
        rounded
        depressed
        v-if="!$props.buy"
        @click="buyRule()"
        color="primary"
        :loading="loading"
    >
      订阅
    </v-btn>
    <v-btn
        rounded
        outlined
        v-if="$props.buy"
        color="primary"
        @click="download()"
    >
      下载
    </v-btn>
  </v-card>
</template>

<script>
import {ruleBuy} from "../request/api";

export default {
  name: "RuleFollow",
  props: ["buy", "rUid", "index"],
  data: () => ({
    loading: false,
  }),
  methods: {
    /**
     * 购买规则。
     */
    buyRule() {
      this.loading = true
      ruleBuy(this.$props.rUid).then(() => {
        this.$emit("resultBuy", {
          "buy": !this.$props.buy,
          "index": !this.$props.index
        })
        this.loading = false
      })
    },
    download() {
      location.href = "rule://" + this.$props.rUid
    }
  }
}
</script>

<style scoped>

</style>