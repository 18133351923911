import Index from "../views/tools/Index";
import RuleSwitch from "../views/tools/RuleSwitch";

export default {
    path: '/tools',
    name: '工具',
    component: Index,
    children: [
        {
            path: 'ruleSwitch',
            name: '规则转换',
            component: RuleSwitch
        },
    ]
};