export default {
    /**
     * 生成uuid。
     * @returns {string}
     */
    uuid() {
        let temp_url = URL.createObjectURL(new Blob());
        let uuid = temp_url.toString();
        URL.revokeObjectURL(temp_url);
        return uuid.substr(uuid.lastIndexOf("/") + 1);
    }, /**
     * v1.2版本的规则转v2.0版本的规则。
     * @param v1
     */
    v1ToV2(v1) {
        const v2 = {
            "a": "2.0", "b": 5, "c": []
        }
        const packageList = []
        v1.c.forEach(packageInfo => {
            const v2P = {
                "a": {
                    "a": packageInfo.a, "b": packageInfo.b, "c": 0
                }, "b": []
            }

            packageInfo.c.forEach(rule => {
                v2P.b.push({
                    "a": {
                        "a": this.uuid(),
                        "b": rule.a,
                        "c": rule.b,
                        "d": rule.c,
                        "e": rule.e,
                        "f": rule.g,
                        "g": rule.i,
                        "h": ""
                    }, "b": [], "c": rule.j.split(","), "d": [], "e": {
                        "a": rule.k, "b": rule.l
                    }, "f": "", "g": "", "h": {
                        "a": "", "b": "[]", "c": "", "d": ""
                    }, "i": {
                        "a": "POST", "b": "http://", "c": "[]", "d": "[]"
                    }, "j": [], "k": "{\"barragePosition\":0}"
                })
            })
            packageList.push(v2P)
        })
        v2.c = packageList
        return JSON.stringify(v2)
    },

    /**
     * v2.0版本的规则转v1.2版本的规则。
     * @param v2
     */
    v2ToV1(v2) {
        let v1 = {
            "a": "1.2", "b": 3, "c": []
        }

        let packageList = []
        v2.c.forEach(packageInfo => {
            let v1Package = {
                "a": packageInfo.a.a, "b": packageInfo.a.b, "c": []
            }

            packageInfo.b.forEach(rule => {
                v1Package.c.push({
                    "a": rule.a.b,
                    "b": rule.a.c,
                    "c": rule.a.d,
                    "d": 0,
                    "e": rule.a.e,
                    "f": "",
                    "g": rule.a.f,
                    "h": "",
                    "i": rule.a.g,
                    "j": rule.c.toString(),
                    "k": rule.e.a,
                    "l": rule.e.b,
                    "m": true,
                    "n": true
                })
            })

            packageList.push(v1Package)
        })
        v1.c = packageList
        return JSON.stringify(v1)
    },

    /**
     * v2.0版本的规则转通知滤盒的规则。
     * @param v2
     * @returns {string}
     */
    v2ToBox(v2) {
        let v1 = {
            "rules": [], "uid": "000000-000000-000000"
        }

        let packageList = []
        v2.c.forEach(packageInfo => {
            packageInfo.b.forEach(rule => {
                let v1Package = {
                    "appCondition": {
                        "appUIDs": [{
                            "packageName": packageInfo.a.b, "user": packageInfo.a.c
                        }], "type": 0
                    }, "chargeCondition": {
                        "type": 0
                    }, "description": "13123123", "disable": false, "id": 3, "screenCondition": {
                        "type": 0
                    }, "textCondition": {
                        "ignoreCase": true, "regex": "", "strs": [], "strs1": [], "strs2": [], "type": 3
                    }, "timeCondition": {
                        "end": 0, "start": 0
                    }, "type": {
                        "delay_time": 0,
                        "extra_action": {
                            "actionNames": [], "enabled": false
                        },
                        "force_mute": false,
                        "hide_template": {
                            "id": 3, "message": "Received a notification from {app}", "title": "{app}"
                        },
                        "include_ongoing": false,
                        "later_time": 0,
                        "later_times": [0],
                        "sound_uri": "",
                        "type": 0,
                        "webhook": {
                            "distinct": false, "method": 1
                        }
                    }, "weekCondition": {
                        "days": [1, 2, 3, 4, 5, 6, 7]
                    }
                }
                v1Package.description = rule.a.d
                v1Package.textCondition.regex = `(${rule.a.f}|${rule.a.g}|${rule.a.h})`
                packageList.push(v1Package)
            })

        })
        v1.rules = packageList
        return JSON.stringify(v1)
    },

    /**
     * 通知滤盒的规则转通知过滤2.0版本的规则。
     * @param box
     * @returns {string}
     * @constructor
     */
    Box2_3ToFilter2_6(box) {
        const v2 = {
            "a": "2.0.1", "b": 6, "c": []
        }
        const packageList = []
        // 应用信息
        box.rules.forEach(value => {
            value.appCondition.appUIDs.forEach(packageInfo => {
                const startTime = new Date(value.timeCondition.start)
                const endTime = new Date(value.timeCondition.end)
                const startDate = `${startTime.getHours() >= 10 ? startTime.getHours() : "0" + startTime.getHours()}:${startTime.getMinutes() >= 10 ? startTime.getMinutes() : "0" + startTime.getMinutes()}`
                const endDate = `${endTime.getHours() >= 10 ? endTime.getHours() : "0" + endTime.getHours()}:${endTime.getMinutes() >= 10 ? endTime.getMinutes() : "0" + endTime.getMinutes()}`
                const v2P = {
                    "a": {
                        "a": value.appCondition.type === 2 ? "所有应用" : packageInfo.packageName,
                        "b": value.appCondition.type === 2 ? "*" : packageInfo.packageName,
                        "c": value.appCondition.type === 2 ? 0 : packageInfo.user
                    }, "b": [{
                        "a": {
                            "a": this.uuid(),// rUid
                            "b": "",// 用户id
                            "c": 0,// versionCode
                            "d": 0, // price
                            "e": value.description.substr(0, 35), // ruleName
                            "f": value.description, // ruleDescription
                            "g": -1, // filterRange 丢弃
                            "h": 0,
                            "i": `${value.textCondition.strs.toString().replaceAll(",", "|")}`, // ruleTitle,
                            "j": 0,
                            "k": `${value.textCondition.strs.toString().replaceAll(",", "|")}`, // ruleContent
                            "l": 0,
                            "m": `${value.textCondition.strs.toString().replaceAll(",", "|")}`, // ruleButton
                            "n": value.type.delay_time === 0 ? 0 : value.type.delay_time / 1000 + 1, // delayRun
                            "o": value.timeCondition.start === 0 && value.timeCondition.end === 0 ? [] : [`${startDate}-${endDate}`], // runTimeRanges
                            "p": true, // isUse
                            "q": false // isUpload
                        },
                        "b": [], // range
                        "c": [], // channel
                        "d": [], // event
                        "e": [], // openApps
                        "f": {
                            "a": value.type.hide_template.title.replaceAll("{app}", "##"),
                            "b": value.type.hide_template.message.replaceAll("{app}", "##")
                        }, // output
                        "g": {
                            "a": 0
                        }, // clickNotification
                        "h": {
                            "a": 0, "b": value.type.extra_action.actionNames
                        }, // clickButton
                        "i": {
                            "a": 5, "b": 2
                        }, // barrage
                        "j": {
                            "a": value.type.sound_uri, "b": 0
                        }, // ringtone
                        "k": {
                            "a": ""
                        }, // vibration
                        "l": {
                            "a": "", "b": "[]", "c": "", "d": ""
                        }, // shareToApp
                        "m": {
                            "a": "POST",
                            "b": value.type.webhook.distinct ? value.type.webhook.url : "http://",
                            "c": "[]",
                            "d": "[]"
                        }, // shareToServer
                        "n": [], // testNotificationList
                        "o": "{\"barragePosition\":0}" // config
                    }]
                }
                if (value.type.type === 0) {
                    v2P.b[0].d.push(0)
                }
                if (value.type.extra_action.enabled) {
                    v2P.b[0].d.push(7)
                }
                if (value.type.type === 3) {
                    v2P.b[0].d.push(11)
                }
                if (value.type.webhook.distinct) {
                    v2P.b[0].d.push(14)
                }
                packageList.push(v2P)
            })
        })
        v2.c = packageList
        return JSON.stringify(v2)
    },

    /**
     * Buzzkill13.5的规则转通知过滤2.6版本的规则。
     * @param buzz
     * @returns {string}
     * @constructor
     */
    Buzz13_5ToFilter2_6(buzz) {
        const v2_6 = {
            "a": "2.0.1", "b": 6, "c": []
        }
        const app = new Set()
        buzz.rules.forEach(value => {
            if (value.apps.length === 0) {
                app.add({
                    "a": {
                        "a": "所有应用",
                        "b": "*",
                        "c": 0
                    },
                    "b": []
                })
            } else {
                value.apps.forEach(item => {
                    app.add({
                        "a": {
                            "a": item,
                            "b": item,
                            "c": 0
                        },
                        "b": []
                    })
                })
            }
        })
        v2_6.c = Array.from(app)

        // 应用信息
        buzz.rules.forEach(value => {
            if (value.apps.length === 0) {
                v2_6.c.forEach(item => {
                    console.log(item);
                })
            } else {
                value.apps.forEach(item => {
                    const index = this.packageNameIsExist(v2_6.c, item);
                    let ruleData = []
                    if (value.keywords !== undefined) {
                        console.log(value.keywords);
                        value.keywords.items.forEach(keyword => {
                            ruleData.push(keyword.text)
                        })
                    }
                    v2_6.c[index].b.push({
                        "a": {
                            "a": this.uuid(),// rUid
                            "b": "",// 用户id
                            "c": 0,// versionCode
                            "d": 0, // price
                            "e": "Buzzkill导出的规则", // ruleName
                            "f": "Buzzkill导出的规则", // ruleDescription
                            "g": -1, // filterRange 丢弃
                            "h": 0,
                            "i": ruleData.toString().replaceAll(",","|").replace("[","").replace("]",""), // ruleTitle,
                            "j": 0,
                            "k": ruleData.toString().replaceAll(",","|").replace("[","").replace("]",""), // ruleContent
                            "l": 0,
                            "m": ruleData.toString().replaceAll(",","|").replace("[","").replace("]",""), // ruleButton
                            "n": value.configuration.delay / 1000, // delayRun
                            "o": [], // runTimeRanges
                            "p": true, // isUse
                            "q": false // isUpload
                        },
                        "b": [], // range
                        "c": [], // channel
                        "d": [], // event
                        "e": [], // openApps
                        "f": {
                            "a": "##",
                            "b": "##"
                        }, // output
                        "g": {
                            "a": 0
                        }, // clickNotification
                        "h": {
                            "a": 0, "b": []
                        }, // clickButton
                        "i": {
                            "a": 5, "b": 2
                        }, // barrage
                        "j": {
                            "a": "", "b": 0
                        }, // ringtone
                        "k": {
                            "a": ""
                        }, // vibration
                        "l": {
                            "a": "", "b": "[]", "c": "", "d": ""
                        }, // shareToApp
                        "m": {
                            "a": "POST",
                            "b": "http://",
                            "c": "[]",
                            "d": "[]"
                        }, // shareToServer
                        "n": [], // testNotificationList
                        "o": "{\"barragePosition\":0}" // config
                    })
                })
            }
            /*value.appCondition.appUIDs.forEach(packageInfo => {
                const startTime = new Date(value.timeCondition.start)
                const endTime = new Date(value.timeCondition.end)
                const startDate = `${startTime.getHours() >= 10 ? startTime.getHours() : "0" + startTime.getHours()}:${startTime.getMinutes() >= 10 ? startTime.getMinutes() : "0" + startTime.getMinutes()}`
                const endDate = `${endTime.getHours() >= 10 ? endTime.getHours() : "0" + endTime.getHours()}:${endTime.getMinutes() >= 10 ? endTime.getMinutes() : "0" + endTime.getMinutes()}`
                const v2P =
                if (value.type.type === 0) {
                    v2P.b[0].d.push(0)
                }
                if (value.type.extra_action.enabled) {
                    v2P.b[0].d.push(7)
                }
                if (value.type.type === 3) {
                    v2P.b[0].d.push(11)
                }
                if (value.type.webhook.distinct) {
                    v2P.b[0].d.push(14)
                }
                packageList.push(v2P)
            })*/
        })
        return JSON.stringify(v2_6)
    },
    packageNameIsExist(array, packageName) {
        let index = 0
        array.forEach((value, item) => {
            if (value.a.b === packageName) {
                index = item
            }
        })
        return index
    }
}