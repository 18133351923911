import axios from 'axios'
import store from "../store";
import router from "../router";

// 环境的切换
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://127.0.0.1'
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = 'https://www.ceshi.com'
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'http://notificationfilter.com'
}

axios.defaults.timeout = 15000;
axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers["Accept"] = "application/json"

// 请求拦截
axios.interceptors.request.use(
    config => {
        const token = store.state.user.token
        config.headers.Authorization = "Bearer " + token
        return config
    },
    error => Promise.reject(error)
)

// 响应拦截
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        switch (error.response.status) {
            case 401:
                router.push("/login").then()
                break;
            default:
                break;
        }
        store.state.snackbar = true
        store.state.snackbarText = error.response.data.message
        return Promise.reject(error);
    })

export function get(url, params) {
    return axios({
        method: "GET",
        url,
        params,
        headers: {
            "Accept-Check": "",
        },
    });
}

export function post(url, data, params = "") {
    return axios({
        method: "POST",
        url,
        headers: {
            "Accept-Check": window.btoa(JSON.stringify(data))
        },
        data,
        params,
    })
}

export function Delete(url, data, params = "") {
    return axios({
        method: "DELETE",
        url,
        headers: {
            "Accept-Check": window.btoa(JSON.stringify(data))
        },
        data,
        params,
    })
}

export function put(url, data, params = "") {
    return axios({
        method: "PUT",
        url,
        headers: {
            "Accept-Check": window.btoa(JSON.stringify(data))
        },
        data,
        params,
    })
}

