<template>
  <v-container>
    <NoData v-if="loading===false&&rules.length===0"></NoData>
    <v-row>
      <v-col
          cols="12"
          md="6"
          lg="3"
          v-for="(rule,index) in rules"
          :key="rule.rUid"
      >
        <v-list-item three-line>
          <v-list-item-content
              @click="$router.push('/rules/'+ rule.rUid +'/info')"
          >
            <v-list-item-title v-text="rule.ruleName"></v-list-item-title>
            <v-list-item-subtitle v-text="rule.ruleDescription">
            </v-list-item-subtitle>
            <v-card elevation="0">
              <v-rating
                  :value="rule.star"
                  disabled="true"
                  length="5"
              ></v-rating>
              <v-overlay
                  absolute
                  value="true"
                  opacity="0"/>
            </v-card>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
                text
                disabled
            >
              <v-icon>mdi-currency-usd</v-icon>
              <span v-text="rule.price">0</span>
            </v-btn>

            <rule-follow :buy="rule.buy" :r-uid="rule.rUid" :index="index" @resultBuy="updateRuleBuy"></rule-follow>
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <!--换页-->
        <div class="text-center">
          <v-pagination
              v-if="rules.length>0"
              v-model="page.index"
              :length="page.length"
              :total-visible="7"
              @input="loadRule()"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RuleFollow from "../../components/RuleFollow";
import {userBuy} from "../../request/api";
import NoData from "../../components/NoData";

export default {
  name: "Buy",
  components: {RuleFollow, NoData},
  data: () => ({
    loading: true,
    rules: [],
    page: {
      "length": 1,
      "index": 1,
    },
  }),
  methods: {
    updateRuleBuy(data) {
      this.rules[data.index]["buy"] = data.buy
    },
    loadRule() {
      userBuy(this.$store.state.user.uuid, {
        "page": this.page.index,
        "count": 10
      })
          .then(response => {
            this.loading = false
            this.rules = response.data.data.list
            this.page.length = response.data.meta.last_page
          })
          .catch(() => {
            this.loading = false
          })
    }
  },
  created() {
    this.loadRule()
  }
}
</script>

<style scoped>

</style>