<template>
  <v-container class="mt-6">
    <v-row>
      <v-col v-for="(item, i) in devices" cols="12" :key="i">
        <v-card
            class="rounded-xl"
            :color="checkDevice(item.deviceUuid)"
        >
          <v-card-title v-text="item.deviceName"></v-card-title>
          <v-card-subtitle v-text="item.date"></v-card-subtitle>
          <v-card-actions>
            <v-btn
                rounded
                color="error"
                text
                :loading="loading"
                :disabled="loading"
                @click="showDeleteDialog(item.id)"
            >删除
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          提示
        </v-card-title>

        <v-card-text>
          点击确定以后该设备就需要重新登录。
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="success darken-1"
              text
              @click="dialog = false"
          >
            取消
          </v-btn>

          <v-btn
              color="error darken-1"
              text
              @click="deleteDevice"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {userDevice, userDevices} from "../../request/api";

const md5 = require('md5')

export default {
  name: "Devices",
  data: () => ({
    selectedItem: 1,
    devices: [],
    loading: false,
    dialog: false,
    id: 0,
  }),
  methods: {
    loadDevices() {
      userDevices().then(response => {
        this.devices = response.data.data
      })
    },
    checkDevice(deviceUuid) {
      return deviceUuid === md5(encodeURI(navigator.appVersion)) ? 'primary' : ''
    },
    showDeleteDialog(id) {
      this.dialog = true
      this.id = id
    },
    deleteDevice() {
      this.loading = true
      userDevice(this.id).then(() => {
        this.$store.state.snackbar = true
        this.$store.state.snackbarText = "删除成功！"
        this.devices.filter((value, index) => {
          if (value.id === this.id) {
            this.devices.splice(index, 1)
          }
        })
        this.dialog = false
        this.loading = false
      })
    }
  },
  created() {
    this.loadDevices()
  }
}
</script>

<style scoped>

</style>